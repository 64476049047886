import React from 'react'
import styled from 'styled-components'
// Components
import PricingTable from '../Elements/PricingTable'

export default function Pricing() {
  return (
    <Wrapper id='pricing'>
      <div className='whiteBg'>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Check Our Pricing</h1>
            <p className='font13'>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
          <TablesWrapper
            className='flexSpaceNull'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <TableBox>
              <PricingTable
                icon='monitor'
                price='$34.99/mo'
                title='Pro'
                text='Pro version gives you the best experience of Fblister. '
                offers={[
                  { name: 'Free Updates', cheked: true },

                  { name: 'Email Support', cheked: true },
                  { name: 'Single Account', cheked: true },

                  { name: 'Unlimited Listing', cheked: true },

                  { name: 'Extension Updates', cheked: true },
                  { name: 'Secured Payment', cheked: true },
                ]}
                action={() => alert('clicked')}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`
const TableBox = styled.div`
  width: 31%;
  box-shadow: 0 0 15px rgba(237, 232, 237, 0.5);

  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`
