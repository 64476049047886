import React from 'react'
import { Card, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

import notavailable from '../../assets/notavailable.png'
const CardMobile = ({ onClick, post, postref }) => {
  var imgs = post.files.split(',')
  return (
    <Card style={{ borderWidth: '0px', borderColor: 'black' }}>
      {/* <Link className='text-link' to={`/place/${post._id}`}>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Card.Img
            className='cardimg'
            // height={240}

            variant='top'
            src={
              imgs[0]
                ? 'https://gomushroomhunting.s3.us-east-2.amazonaws.com/' +
                  imgs[0]
                : ''
            }
            alt='Mushroom Image'
          />{' '}
        </div>
      </Link>
      <Link className='text-link' to={`/place/${post._id}`}>
        <Card.Body>
          <Card.Title>{post.title}</Card.Title>
          <Card.Text className='block-with-text'>{post.details}</Card.Text>
        </Card.Body>
      </Link> */}
      <Link className='text-link' to={`/item/${post.id ? post.id : post._id}`}>
        <Card.Img
          // height={290}
          variant='top'
          src={
            imgs[0]
              ? 'https://gomushroomhunting.s3.us-east-2.amazonaws.com/' +
                post.files
              : notavailable
          }
        />

        <Card.Body>
          <Card.Title className='Card-Title'>{post.title}</Card.Title>
          {/* <Card.Text className='Card-Text'>{post.details}</Card.Text> */}

          {/* <div className='types'>
            {post.mushroomType.map((type) => (
              <div className='tag'>{type.label}</div>
            ))}
          </div>

          <div className='date-time my-2'>
            <div>
              <i className='fa-solid fa-calendar'></i>{' '}
              {moment(post.sightingDate).format('ll')} to{' '}
              {moment(post.sightingDate2).format('ll')}
            </div>
            <div>
              <i className='fa-solid fa-clock mricon my-2'></i>
              {post.time} to {post.time2}
            </div>
          </div> */}

          <div className='stars-price my-2'>
            <div className='stars'>
              <i className='fa-solid fa-star py-1 mricon'></i>
              {post.rating == 0 ? 'No reviews yet' : post.rating}
            </div>
            <div className='tag-p price-money mx-1'>
              {post.minPrice} $ - {post.maxPrice}$
            </div>
          </div>
        </Card.Body>
      </Link>
    </Card>
  )
}

export default CardMobile
