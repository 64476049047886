import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Container } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'

import axios from 'axios'
import { AuthContext } from '../AuthProvider'

const ChangePasswordScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setconfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const context = useContext(AuthContext)
  const { user, setuser, authLoader } = context
  const token = location.search ? location.search.split('=')[1] : '/'

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const verify_token = async () => {
    try {
      await axios.put(
        `/api/v1/user/verifytoken`,
        {
          token,
        },
        config
      )
    } catch (error) {
      setErrorMessage('')
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }
  useEffect(() => {
    if (user) {
      history.push('/dashboard')
    }
  }, [history, user])
  useEffect(() => {
    verify_token()
  }, [])

  const submitHandler = async (e) => {
    e.preventDefault()

    if (password === confirmPassword) {
      try {
        setLoading(true)

        const { data } = await axios.put(
          `/api/v1/user/changepassword`,
          {
            password,
            token,
          },
          config
        )
        setLoading(false)

        if (data.data) {
          history.push('/login')
        } else {
          setErrorMessage('Password change failed')
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)

        setErrorMessage('')
        setError(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        )
      }
    } else {
      setErrorMessage("Confirm password didn't match ")
    }
  }

  return (
    <Container className='idk'>
      <div className='insider'>
        {loading && <Loader />}

        <h3 className='py-2'>Reset Password</h3>
        {errorMessage && <Message variant='danger'> {errorMessage}</Message>}
        {error ? (
          <Message variant='danger'> {error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='password'>
              <Form.Label className='formlabel'>New Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                minLength={6}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='confirmpassword'>
              <Form.Label className='formlabel'>
                Confirm New Password
              </Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm password'
                value={confirmPassword}
                onChange={(e) => setconfirmPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button
              type='submit'
              variant='primary'
              className='w-100 uploadbutton'
            >
              Reset Password{' '}
            </Button>{' '}
          </Form>
        )}
      </div>
    </Container>
  )
}

export default ChangePasswordScreen
