import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import {
  Container,
  Carousel,
  Image,
  ListGroup,
  Row,
  Col,
  Modal,
  Button,
  Spinner,
  DropdownButton,
  Dropdown,
  Form,
  ListGroupItem,
} from 'react-bootstrap'
// import defaultMush from '../avatar/defaultmush.png'
import Loader from '../components/Loader'
import Message from '../components/Message'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { AuthContext } from '../AuthProvider'
import MushroomType from '../components/cards/MushroomType'
import ShareModal from '../modals/ShareModal'
import Avatar from 'react-avatar'
import { API_URL, AVATAR_URL } from '../constants/Datas'
import SuccessToast from '../components/toasts/SuccessToast'
import { MAX_COMMENT_SIZE } from '../constants/Sizes'
import AuthLoader from '../components/AuthLoader'
import Header from '../components/Header'
import ImageGallery from 'react-image-gallery'
import notavailable from '../assets/notavailable.png'
import notavailable1 from '../assets/notavailable-1.png'
import ReactStars from 'react-stars'
import Rating from '../components/Rating'
import { Helmet } from 'react-helmet-async'
import thumbnail from '../assets/banner.png'

const metaDecorator = require('../assets/metaDecorator')
// export const RatingContainer = () => {
//   const [rate, setRate] = useState(3);

//   return (
//     <div>
//       <StarRating setRating={(val) => setRate(val)} ratingValue={rate} />
//     </div>
//   );
// };

const PlaceDetails = ({ history, match }) => {
  const context = useContext(AuthContext)
  const [modalShow, setModalShow] = useState(false)
  const [sharemodalShow, setShareModalShow] = useState(false)
  const [sharelink, setsharelink] = useState('')
  const [loading, setLoading] = useState(false)
  const [place, setPlace] = useState({ data: '' })
  const [errorMessage, seterrorMessage] = useState(null)
  const [address, setAddress] = useState('')
  const [mushrooms, setmushrooms] = useState([])
  const [fav, setfav] = useState(false)
  const [mushroomPost, setMushroomPost] = useState({})
  const [userload, setuserLoad] = useState(false)
  const [postCreator, setpostCreator] = useState(null)
  const [userID, setUserID] = useState('')
  const [commentLoader, setCommentLoader] = useState(false)
  const [questionLoader, setQuestionLoader] = useState(false)

  const [activeComment, setactiveComment] = useState(null)

  const isReplying = activeComment && activeComment.type === 'reply'

  const [reacted, setReacted] = useState(false)
  const [numofLikes, setnumofLikes] = useState('0')
  const [latlng, setLatLng] = useState({
    latitude: '',
    longitude: '',
  })
  const [postComments, setPostComments] = useState([])
  const [postQuestion, setpostQuestions] = useState([])
  const [enlargedImg, setenlargedImg] = useState('')
  const { setCoordinates, user, authLoader } = context
  const [comment, setComment] = useState('')
  const [victoast, setVicToast] = useState('')
  const [vicshow, setVicShow] = useState(false)
  const [success, setSuccess] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [rate, setRate] = useState(0)
  const [reply, setReply] = useState('')
  const [showReply, setShowReply] = useState(false)

  const [question, setQuestion] = useState('')
  const [questionError, setquestionError] = useState('')

  const [reviewError, setreviewError] = useState('')
  //   var imgs = post.files.split(',')
  //   .then(function (response) {
  //     setPlace({ data: response.data.data })
  //     var imgs = response.data.data.files.split(',')
  //     setimgs(imgs)
  //     setLoading(false)
  //   })

  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null
  let url = ''
  const [userLiked, setuserLiked] = useState([])
  const deletePlaceHandler = async () => {
    setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    try {
      await axios.delete(`/api/v1/places/${match.params.id}`, config)
      setLoading(false)

      history.push('/')
    } catch (error) {
      setLoading(false)

      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  function arrtoObj(files, captions) {
    const arr = files.split(',')
    const obj3 = { original: '', thumbnail: '', description: '' }

    console.log(captions)
    if (arr[0] != '') {
      var newarr = arr.map((original, i) => ({
        original: API_URL + original,
        thumbnail: API_URL + original,
        description: captions[i] ? captions[i].caption : '',
      }))
    } else {
      var newarr = []
    }

    return newarr
  }

  const [imgs, setimgs] = useState([])
  const fetchPlace = async () => {
    try {
      {
        !commentLoader && setLoading(true)
      }
      await axios
        .get(`/api/v1/places/${match.params.id}`)
        .then(function (response) {
          setPlace({ data: response.data.data })
          var imgs = arrtoObj(
            response.data.data.files,
            response.data.data.captions
          )
          setimgs(imgs)
          setAddress(response.data.data.location.formattedAddress)
          setLatLng({
            latitude: response.data.data.location.coordinates[1],
            longitude: response.data.data.location.coordinates[0],
          })
          setmushrooms(response.data.data.mushroomType)
          getuserbyID(response.data.data.user)
          setUserID(response.data.data.user)
          setPostComments(response.data.data.comments)
          setpostQuestions(response.data.data.questions)

          setLoading(false)
        })
    } catch (error) {
      setCommentLoader(false)

      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  const getActualFav = async () => {
    setReacted(true)

    const { data } = await axios.get(
      `/api/v1/places/userlike/${match.params.id}`
    )

    setnumofLikes(data.numLikes)
    setuserLiked(data.likes)

    if (user) {
      if (
        data.likes.filter(
          (like) => like.user.toString() === user._id.toString()
        ).length > 0
      ) {
        setfav(true)
      } else {
        setfav(false)
      }
    }
    setReacted(false)
  }

  const getuserbyID = async (id) => {
    setuserLoad(true)
    const { data } = await axios.get(`/api/v1/user/getuser/${id}`)
    setpostCreator(data)
    setuserLoad(false)
  }

  useEffect(() => {
    fetchPlace()
    setSuccess(false)
  }, [match, success])

  useEffect(() => {
    getActualFav()
    setReacted(false)
  }, [fav, match, history])
  const handleLinkClick = () => {
    setCoordinates({
      latitude: latlng.latitude,
      longitude: latlng.longitude,
      formattedAddress: address,
    })
  }
  function ImageModal(props) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Image
            className='englargedmoal'
            src={enlargedImg}
            alt='Enlarged Mushroom Image'
          />
        </Modal.Body>
      </Modal>
    )
  }

  const ratingChanged = (newRating) => {
    setRate(newRating)
  }

  const submitCommentHandler = async (e) => {
    e.preventDefault()
    // setLoading(true)

    try {
      if (comment && rate > 0) {
        setCommentLoader(true)

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
        const { data } = await axios.post(
          `/api/v1/places/${match.params.id}/comments`,
          { comment, rate },
          config
        )
        if (data) {
          setVicShow(true)
          setSuccess(true)
          setVicToast('Comment Added')
        }
        setSuccess(true)

        setComment('')
        setCommentLoader(false)
        setRate(0)
      } else {
        setreviewError('Please give an honest rating')
      }
    } catch (error) {
      setCommentLoader(false)
      setComment('')

      setreviewError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const submitReplyHandler = async (e) => {
    e.preventDefault()
    // setLoading(true)

    try {
      if (reply) {
        // setQuestionLoader(true)

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        const id = activeComment.id
        const { data } = await axios.post(
          `/api/v1/places/${match.params.id}/replies`,
          { reply, id },
          config
        )
        if (data) {
          setVicShow(true)
          setSuccess(true)
          setVicToast('Reply Added')
        }
        setSuccess(true)

        setReply('')
        // setQuestionLoader(false)
      } else {
        setquestionError('Please post a valid Reply')
      }
    } catch (error) {
      // setQuestionLoader(false)

      setquestionError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const submitQuestionHandler = async (e) => {
    e.preventDefault()
    // setLoading(true)

    try {
      if (question) {
        setQuestionLoader(true)

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
        const { data } = await axios.post(
          `/api/v1/places/${match.params.id}/questions`,
          { question },
          config
        )
        if (data) {
          setVicShow(true)
          setSuccess(true)
          setVicToast('Question Added')
        }
        setSuccess(true)

        setQuestion('')
        setQuestionLoader(false)
      } else {
        setquestionError('Please post a valid Question')
      }
    } catch (error) {
      setQuestionLoader(false)

      setquestionError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const handleShare = () => {
    if (typeof window === 'object') {
      url = String(window.location)
    }
    setsharelink(url)
    setShareModalShow(true)
  }
  const images = [
    {
      // original: notavailable,

      original:
        'https://dummyimage.com/900x400/ffffff/000000&text=Image+Not+Available',
    },
  ]

  return (
    <div>
      <Helmet>
        <title> {place.data.title ? place.data.title : 'Product'} </title>
        <meta
          name='description'
          content='The product page offers details about a certain product that is being offered. It typically contains information on the product name, pictures, price, description, features, reviews, and any other pertinent factors that could aid a potential buyer in making an informed choice'
        />
        <link rel='canonical' href='/item/:id'></link>
        <meta property='og:title' content='Product' />
        <meta
          property='og:description'
          content='The product page offers details about a certain product that is being offered. It typically contains information on the product name, pictures, price, description, features, reviews, and any other pertinent factors that could aid a potential buyer in making an informed choice'
        />
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />{' '}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>
      <Header />
      <div className='main-flow'>
        {loading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader />
          </div>
        ) : (
          <Container className='post-container-details'>
            {errorMessage && <Message variant='danger'>{errorMessage}</Message>}

            <div>
              {/* <Carousel variant='dark' hover='pause'>
                  {imgs.map((url) => (
                    <Carousel.Item
                      onClick={() => {
                        setenlargedImg(url ? API_URL + url : '')
                        setModalShow(true)
                      }}
                    >
                      <Image
                        src={url ? API_URL + url : 'defaultMush'}
                        alt='Mushroom Image'
                        fluid
                      />
                    </Carousel.Item>
                  ))}
                </Carousel> */}
              {/* <div className='gallery'>
                  <ImageGallery items={images} />
                </div> */}

              <ImageGallery
                loading='lazy'
                lazyLoad
                items={imgs.length > 0 ? imgs : images}
              />
              <Row>
                {' '}
                <Col>
                  {' '}
                  <ListGroup variant='flush'>
                    {user ? (
                      <ListGroup.Item>
                        {userID == user._id ? (
                          <Row
                            style={{
                              display: 'flex',
                              flex: 1,
                              justifyContent: 'flex-end',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              style={{ maxWidth: '100px' }}
                              type='button'
                              variant='light'
                              onClick={handleShow}
                              className='mx-1'
                            >
                              <i className='fas fa-trash fa-lg mricon'></i>
                              Delete
                            </Button>
                            {/* <Link to={`/editpost/${match.params.id}`}> */}
                            <Button
                              type='button'
                              variant='light'
                              style={{ maxWidth: '100px' }}
                              onClick={() => {
                                history.push(`/editpost/${match.params.id}`)
                              }}
                            >
                              <i className='fas fa-edit  fa-lg mricon'></i>
                              Edit
                            </Button>
                            {/* </Link> */}
                          </Row>
                        ) : null}
                      </ListGroup.Item>
                    ) : null}

                    <ListGroup.Item>
                      <Row className='py-1'>
                        <Col
                          className='col-details'
                          xs={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <Rating value={place.data.rating} />{' '}
                          {place.data.numComments} reviews
                        </Col>

                        <Col
                          className='col-details'
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                          onClick={handleShare}
                        >
                          <i className='far fa-share-square px-2 fa-lg'></i>
                          Share
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Location
                        </Col>

                        <Col className='col-details'>
                          <Link
                            className='location-text-link'
                            to={'/'}
                            onClick={handleLinkClick}
                          >
                            {address}
                          </Link>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Price{' '}
                        </Col>
                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          ${place.data.minPrice} - ${place.data.maxPrice}
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Availability{' '}
                        </Col>
                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          {moment(place.data.sightingDate).format('ll')} - {''}
                          {moment(place.data.sightingDate2).format('ll')}
                          <div>
                            {place.data.time} - {place.data.time2}
                          </div>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Item Category
                        </Col>
                        <Col
                          md={4}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            // backgroundColor: 'red',
                          }}
                        >
                          <Row>
                            <div
                              className='types'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {mushrooms.map((type) => (
                                <div className='tag'>{type.label}</div>
                              ))}
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            // justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Avatar
                            src={AVATAR_URL}
                            size={50}
                            className='sb-avatar'
                            style={{ margin: 10 }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              // justifyContent: 'center',
                            }}
                          >
                            Posted by
                            <Link
                              className='location-text-link'
                              to={`/profile/${place.data.user}`} //change to user id
                            >
                              {place.data.userName}
                            </Link>
                          </div>
                        </Col>

                        <Col
                          // md={10}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          Last Updated <br />
                          {moment(place.data.updatedAt).format('ll')}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <h4>{place.data.title}</h4>
                        <Col className='detailstext my-1'>
                          {place.data.details}
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    {/* hh */}
                    <h4 className='mx-3'>Reviews ({place.data.numComments})</h4>

                    {loading ? (
                      <Loader />
                    ) : postComments ? (
                      <div>
                        {postComments.length === 0 && (
                          <Message variant='info my-1'>No Reviews Yet</Message>
                        )}
                        <ListGroup variant='flush'>
                          {loading && <Loader />}
                          {reviewError && (
                            <Message variant='danger'>{reviewError}</Message>
                          )}

                          {postComments.map((comment) => (
                            <ListGroup.Item key={comment._id}>
                              <Row>
                                <Col>
                                  <Link
                                    className='location-text-link'
                                    to={`/profile/${comment.user}`} //change to user id
                                  >
                                    {comment.name}
                                  </Link>
                                </Col>
                                <Col
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    fontSize: 'small',
                                  }}
                                >
                                  {moment(comment.createdAt).format('ll')}
                                </Col>
                              </Row>
                              <Rating value={comment.rating} />
                              <p className='comment'> {comment.comment}</p>
                            </ListGroup.Item>
                          ))}

                          <ListGroup.Item>
                            {commentLoader && <AuthLoader />}

                            {user ? (
                              <Form onSubmit={submitCommentHandler}>
                                <Form.Group controlId='comment'>
                                  <h4>Write a review</h4>
                                  <ReactStars
                                    className='my-1'
                                    count={5}
                                    value={rate}
                                    onChange={ratingChanged}
                                    size={24}
                                    half={false}
                                    color2={'#ffd700'}
                                  />

                                  <Form.Control
                                    className='my-1'
                                    as='textarea'
                                    row='4'
                                    maxLength={MAX_COMMENT_SIZE}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                  ></Form.Control>
                                </Form.Group>
                                <Button
                                  className='buttonSubmit my-3'
                                  disabled={!comment}
                                  type='submit'
                                  variant='primary'
                                >
                                  Submit
                                </Button>
                              </Form>
                            ) : (
                              <Message variant='warning'>
                                Please{' '}
                                <Link to='/login' className='text-link mx-1'>
                                  Log in
                                </Link>{' '}
                                to write a review{' '}
                              </Message>
                            )}
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    ) : null}
                    <h4 className='mx-3'>
                      Questions About This Product ({place.data.numQuestions})
                    </h4>

                    {postQuestion ? (
                      <div>
                        {postQuestion.length === 0 && (
                          <Message variant='info my-1'>
                            No Questions & Answers Yet
                          </Message>
                        )}
                        <ListGroup variant='flush'>
                          {/* {loading && <Loader />} */}
                          {questionError && (
                            <Message variant='danger'>{questionError}</Message>
                          )}

                          {postQuestion.map((comment) => (
                            <ListGroup.Item key={comment._id}>
                              <Row>
                                <Col>
                                  <Link
                                    className='location-text-link mx-1'
                                    to={`/profile/${comment.user}`} //change to user id
                                  >
                                    {/* <i className='fa-solid fa-message'></i>{' '} */}
                                    {comment.name}
                                  </Link>
                                </Col>
                                <Col
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    fontSize: 'small',
                                  }}
                                >
                                  {moment(comment.createdAt).format('ll')}
                                </Col>
                              </Row>
                              <p className='comment mx-1'>
                                {' '}
                                {comment.question}
                              </p>

                              <Row>
                                <Col>
                                  {comment.replies.map((comment) => (
                                    <p className='comment mx-2'>
                                      {' '}
                                      <i
                                        style={{ color: '#147e4e' }}
                                        className='fa-solid fa-message'
                                      ></i>{' '}
                                      {comment.answer}
                                    </p>
                                  ))}
                                </Col>
                              </Row>
                              {user ? (
                                user._id == userID ? (
                                  <div>
                                    <div
                                      onClick={() => {
                                        setShowReply(true)
                                        setactiveComment({
                                          id: comment._id,
                                          type: 'reply',
                                        })
                                      }}
                                      className={
                                        showReply
                                          ? 'text-button-rep mx-1 notshow'
                                          : 'text-button-rep mx-1 show'
                                      }
                                      style={{ marginBottom: '10px' }}
                                    >
                                      Reply
                                    </div>

                                    {isReplying
                                      ? comment._id === activeComment.id && (
                                          <Form
                                            onSubmit={submitReplyHandler}
                                            className={
                                              showReply ? 'show' : 'notshow'
                                            }
                                          >
                                            <Form.Group controlId='reply'>
                                              <Form.Control
                                                className='my-1'
                                                as='textarea'
                                                row='4'
                                                maxLength={MAX_COMMENT_SIZE}
                                                value={reply}
                                                onChange={(e) =>
                                                  setReply(e.target.value)
                                                }
                                              ></Form.Control>
                                            </Form.Group>
                                            <Button
                                              className='buttonSubmit my-3'
                                              disabled={!reply}
                                              type='submit'
                                              variant='primary'
                                            >
                                              Reply
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                setShowReply(false)
                                              }}
                                              bg='outline-button'
                                              variant='outline-secondary '
                                              className='outline-button mx-2 '
                                            >
                                              Cancel
                                            </Button>{' '}
                                          </Form>
                                        )
                                      : null}
                                  </div>
                                ) : null
                              ) : null}
                            </ListGroup.Item>
                          ))}

                          <ListGroup.Item>
                            {questionLoader && <AuthLoader />}
                            {/* <i class="fa-solid fa-square-a"></i> */}
                            {user ? (
                              <Form onSubmit={submitQuestionHandler}>
                                <Form.Group controlId='comment'>
                                  <h4>Add Question</h4>

                                  <Form.Control
                                    className='my-1'
                                    as='textarea'
                                    row='4'
                                    maxLength={MAX_COMMENT_SIZE}
                                    value={question}
                                    onChange={(e) =>
                                      setQuestion(e.target.value)
                                    }
                                  ></Form.Control>
                                </Form.Group>
                                <Button
                                  className='buttonSubmit my-3'
                                  disabled={!question}
                                  type='submit'
                                  variant='primary'
                                >
                                  Submit
                                </Button>
                              </Form>
                            ) : (
                              <Message variant='warning'>
                                Please{' '}
                                <Link to='/login' className='text-link mx-1'>
                                  Log in
                                </Link>{' '}
                                to submit a question{' '}
                              </Message>
                            )}
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    ) : null}

                    {/* hg */}
                  </ListGroup>
                </Col>
              </Row>
              <ImageModal show={modalShow} onHide={() => setModalShow(false)} />
              <ShareModal
                show={sharemodalShow}
                onHide={() => setShareModalShow(false)}
                url={sharelink}
              />
              <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your post will be deleted</Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    className='prim'
                    variant='primary'
                    onClick={deletePlaceHandler}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Container>
        )}
        <SuccessToast
          vicshow={vicshow}
          setVicShow={setVicShow}
          text={victoast}
        />
      </div>
    </div>
  )
}

export default PlaceDetails
