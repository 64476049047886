import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/gg.svg'
import { ReactComponent as FbLogo } from '../assets/fblisterlogo.svg'
import axios from 'axios'
import { AuthContext } from '../AuthProvider'
import { useMediaQuery } from 'react-responsive'

import {
  isEmail,
  isMatch,
  isLength,
  isEmpty,
} from '../components/utils/validation'
import logow from '../assets/black.svg'

import Message from '../components/Message'
import Loader from '../components/Loader'
import { Helmet } from 'react-helmet-async'
import thumbnail from '../assets/banner.png'

const metaDecorator = require('../assets/metaDecorator')

const LoginScreen = ({ history, location }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const redirect = location.search ? location.search.split('=')[1] : '/'
  const context = useContext(AuthContext)
  const { user, setuser } = context
  const isTabletOrMobile = useMediaQuery({ query: '(max-height: 900px)' })

  useEffect(() => {
    if (user) {
      history.push('/')
    }
  }, [history, user])
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const submitHandler = async (e) => {
    e.preventDefault()
    if (isEmpty(password) || isEmpty(email))
      return setError('Please fill in all the fields.')

    if (!isEmail(email)) return setError('Invalid email format.')
    if (isLength(password))
      return setError('Password must be at least 6 characters.')
    setLoading(true)
    try {
      const { data } = await axios.post(
        '/api/v1/user/login',
        { email, password },
        config
      )

      setuser(data)
      localStorage.setItem('userInfo', JSON.stringify(data))
      setLoading(false)
    } catch (error) {
      setLoading(false)

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  return (
    <>
      {' '}
      <Helmet>
        <title> Login</title>
        <meta
          name='description'
          content='User can login with their email and password.'
        />
        <link rel='canonical' href='/login'></link>
        <meta property='og:title' content='login' />
        <meta
          property='og:description'
          content='User can login with their email and password.'
        />
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />{' '}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>
      <div className='main'>
        <FormContainer>
          <img
            className={isTabletOrMobile ? 'up-icon-r' : 'up-icon'}
            src={logow}
            height={80}
          />
          {loading ? (
            <Loader />
          ) : (
            <Form onSubmit={submitHandler}>
              {error && <Message variant='danger'> {error}</Message>}

              <h5 style={{ marginBottom: '1rem' }}>Log in </h5>
              <h3>Welcome to Farm Fresh! </h3>
              <Form.Group controlId='email'>
                <Form.Label className='formlabel'>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='password'>
                <Form.Label className='formlabel'>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter password'
                  minLength={6}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <div className='text-button-forgot-wrapper'>
                <Link className='text-link' to={'/forgotpassword'}>
                  <div className='text-button py-1'>Forgot Password?</div>
                </Link>
              </div>

              <Button
                type='submit'
                variant='success'
                className='w-100 buttonSubmit'
              >
                Login
              </Button>

              <div className='d-flex align-items-center justify-content-center'>
                Don't have an Account?
                <Link className='text-link' to={'/register'}>
                  <div className='text-button  px-1'>Register here!</div>
                </Link>
              </div>
            </Form>
          )}
        </FormContainer>
        <div className='bg'>
          {/* <FbLogo height='100' width='180' className='px-2 mx-2' /> */}
        </div>
      </div>
    </>
  )
}

export default LoginScreen
