import React, { useContext, useState, useEffect, useRef } from 'react'
import {
  Container,
  Form,
  FormControl,
  Button,
  ListGroup,
  Row,
  Col,
  Image,
  Alert,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import MultiSelect from '../components/MultiSelect'
import PlaceAutocomplete from '../components/PlaceAutocomplete'
import DangerToast from '../components/toasts/DangerToast'
import { IMG_SIZE, MAX_DETAILS_SIZE } from '../constants/Sizes'
import {
  COUNT_VALIDATION,
  INPUT_VALIDATION,
  PLACE_VALIDATION,
  SIZE_VALIDATION,
  DATE_VALIDATION,
  PRICE_VALIDATION,
} from '../constants/ToastTexts'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import TimePicker from 'react-bootstrap-time-picker'

import { AuthContext } from '../AuthProvider'
import axios from 'axios'
import Loader from '../components/Loader'
import Message from '../components/Message'
import SuccessToast from '../components/toasts/SuccessToast'
import AuthLoader from '../components/AuthLoader'
import { Link } from 'react-router-dom'
import { API_URL } from '../constants/Datas'
import Header from '../components/Header'
import { OPTIONS } from '../constants/Datas'
import { Helmet } from 'react-helmet-async'

import thumbnail from '../assets/banner.png'
const metaDecorator = require('../assets/metaDecorator')

const EditPostScreen = ({ history, match }) => {
  const context = useContext(AuthContext)
  const fileRef = useRef(null)
  const [privateSwitch, setprivateSwitch] = useState(false)

  const [value, setTime] = useState('10:00 AM')
  const [value2, setTime2] = useState('10:00 AM')
  const [max, setMax] = useState(null)
  const [min, setMin] = useState(null)
  const [formVal, setFormVal] = useState([])

  const [sightingDate, setSightingDate] = useState(new Date())
  const [mushroomType, SetmushroomType] = useState([])
  const [images, setImages] = useState([])
  const [toast, setToast] = useState('')
  const [show, setShow] = useState(false)
  const [victoast, setVicToast] = useState('')
  const [vicshow, setVicShow] = useState(false)
  const [errorMessage, seterrorMessage] = useState(null)
  const [details, setDetails] = useState('')
  const [likes, setLikes] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploadedImage, setUploadedImage] = useState([])
  const { coordinates, user, authLoader, setCoordinates } = context
  const [formatadress, setFormatAdress] = useState('')
  const [deletedImg, setdeletedImg] = useState([])
  const [title, setTitle] = useState('')
  const [captions, setCaptions] = useState([])
  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null
  const imageHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i]
      newImage['id'] = Math.floor(Math.random() * 100)
      newImage['relativePath'] = URL.createObjectURL(newImage)

      if (newImage.size < IMG_SIZE) {
        const concatted = images.concat(uploadedImage)
        console.log(concatted)
        if (concatted.length < 10) {
          setImages((prevImages) => [...prevImages, newImage])

          setFormVal([...formVal, { caption: '' }])
        } else {
          setShow(true)
          setToast(COUNT_VALIDATION)
        }
      } else {
        setShow(true)
        setToast(SIZE_VALIDATION)
      }
    }
  }

  function handleTimeChange(time) {
    console.log(moment.utc(time * 1000).format('LT')) // <- prints "3600" if "01:00" is picked

    setTime(moment.utc(time * 1000).format('LT'))
  }

  function handleTimeChange2(time) {
    setTime2(moment.utc(time * 1000).format('LT'))
  }
  const removeImageFromFile = (id) => {
    console.log(id)
    const imagess = [...uploadedImage]
    imagess.splice(id, 1)
    setUploadedImage(imagess)

    setdeletedImg((prevImages) => [...prevImages, id])
  }

  const createMainPost = async (files) => {
    setLoading(true)

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    try {
      await axios
        .put(
          `/api/v1/places/${match.params.id}`,
          {
            deletedImg,
            uploadedImage,
            files,
            mushroomType,
            coordinates,
            details,
            likes,
            sightingDate,
            sightingDate2,
            title,
            max,
            min,
            value,
            value2,
            formVal,
          },
          config
        )
        .then(function (response) {
          setVicToast(response.data.message)
          setVicShow(true)
          setLoading(false)
          setImages([])

          history.push(`/item/${match.params.id}`)
          seterrorMessage('')
        })
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  const onHandle = (e, i) => {
    let newForm = [...formVal]

    newForm[i][e.target.name] = e.target.value

    setFormVal(newForm)
  }

  const createPost = async () => {
    setLoading(true)
    const formData = new FormData()
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i])
    }

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      await axios
        .post('/api/v1/upload', formData, config)
        .then(function (response) {
          createMainPost(response.data.files)
        })
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  const submitHandle = () => {
    if (coordinates.formattedAddress) {
      if (mushroomType.length > 0 && sightingDate && title) {
        if (min >= 0 && max >= 0 && min < max) {
          if (sightingDate <= sightingDate2) {
            if (images.length > 0) {
              console.log(formVal)
              createPost()
            } else {
              createMainPost('')
              console.log(formVal)
            }
          } else {
            setShow(true)
            setToast(DATE_VALIDATION)
          }
        } else {
          setShow(true)
          setToast(PRICE_VALIDATION)
        }
      } else {
        setShow(true)
        setToast(INPUT_VALIDATION)
      }
    } else {
      setShow(true)
      setToast(PLACE_VALIDATION)
    }
  }
  // :  errorMessage ? (
  //   <Message variant='danger'>{error}</Message>
  // ) :
  const [sightingDate2, setSightingDate2] = useState(new Date())

  const fetchPlace = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(`/api/v1/places/${match.params.id}`)
      if (data) {
        if (user) {
          if (user._id == data.data.user) {
            setDetails(data.data.details)
            setTitle(data.data.title)
            setSightingDate(new Date(data.data.sightingDate))
            setSightingDate2(new Date(data.data.sightingDate2))
            setFormatAdress(data.data.location.formattedAddress)
            SetmushroomType(data.data.mushroomType)
            setMax(data.data.maxPrice)
            setMin(data.data.minPrice)
            setCaptions(data.data.captions)
            if (data.data.files) {
              var imgs = data.data.files.split(',')
              setUploadedImage(imgs)
              console.log(imgs)
            }
            setCoordinates({
              latitude: data.data.location.coordinates[1],
              longitude: data.data.location.coordinates[0],
              formattedAddress: data.data.location.formattedAddress,
            })

            setDetails(data.data.details)
            // setprivateSwitch(data.data.private)
            setLoading(true)
          } else {
            history.push('/')
          }
        }
      }
      setLoading(false)
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }
  const removeFromLartHandler = (i) => {
    const imagess = [...images]
    imagess.splice(i, 1)
    setImages(imagess)

    const newForm = [...formVal]
    newForm.splice(i, 1)
    setFormVal(newForm)
  }
  useEffect(() => {
    fetchPlace()
  }, [history, user])

  return (
    <div>
      <Helmet>
        <title>Edit Product </title>
        <meta
          name='description'
          content='Edit image, description, and product availability of your listed item. You can also see ratings and reviews of the product from your customers.You can also put your own review.
          '
        />
        <link rel='canonical' href='/editpost/:id'></link>
        <meta property='og:title' content='Edit Product' />

        <meta
          property='og:description'
          content='Edit image, description, and product availability of your listed item. You can also see ratings and reviews of the product from your customers.You can also put your own review.
          '
        />
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />

        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>
      <Header />
      <div className='main-flow'>
        <ReactTooltip id='registerTip' place='top' effect='solid'>
          Put a title for your product
        </ReactTooltip>
        <ReactTooltip id='locationTip' place='top' effect='solid'>
          This is where you found the mushroom. Input can be address, <br />
          landmark, park, city, zip code, county, state, or country.
        </ReactTooltip>
        <ReactTooltip id='privatemode' place='top' effect='solid'>
          Enable Private mode, Only you can see your post and mushroom location.
        </ReactTooltip>

        <ReactTooltip id='pricemax' place='top' effect='solid'>
          Put a valid Maximum Price. Maximum Price should be greater than
          minimum price.
        </ReactTooltip>
        <ReactTooltip id='pricemin' place='top' effect='solid'>
          Put a valid Minimum Price. Minimum Price should be lesser than maximum
          price.
        </ReactTooltip>
        <ReactTooltip id='daterange' place='top' effect='solid'>
          Put a valid date range. Date range depicts the availability of your
          products in those dates.
        </ReactTooltip>
        <ReactTooltip id='timerange' place='top' effect='solid'>
          Put a valid time range. Time range depicts the available time to
          purchase your product.
        </ReactTooltip>

        {loading ? (
          <div className='insider'>
            <div>
              <Loader />
              <h6 className='my-3'>Please Wait</h6>
            </div>
          </div>
        ) : (
          <Container className='post-container'>
            {authLoader ? (
              <AuthLoader />
            ) : user ? null : (
              <Alert
                variant='warning'
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                Please
                <Link className='text-link mx-1' to={'/login'}>
                  <div className='Text'> Login </div>
                </Link>
                to Edit post{' '}
              </Alert>
            )}
            {errorMessage ? (
              <Message variant='danger'>{errorMessage}</Message>
            ) : null}
            <h1>Edit your product </h1>
            <Form.Label>
              General Location{' '}
              <i
                data-tip
                data-for='locationTip'
                className='fas fa-question-circle question-mark'
              ></i>{' '}
            </Form.Label>{' '}
            <PlaceAutocomplete
              buttonVisible={false}
              inputWidth={'25rem'}
              formatadress={formatadress}
            />
            <Form.Group controlId='title'>
              <Form.Label>
                Title{' '}
                <i
                  data-tip
                  data-for='registerTip'
                  className='fas fa-question-circle question-mark'
                ></i>{' '}
              </Form.Label>
              <Form.Control
                disabled={!user}
                required
                value={title}
                maxLength={100}
                type='text'
                placeholder='Post Title'
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Label>
              Date Range
              <i
                data-tip
                data-for='daterange'
                className='fas fa-question-circle question-mark mx-1'
              ></i>{' '}
            </Form.Label>
            <div className='price-range-wrapper'>
              <DatePicker
                wrapperClassName='price-wrapper'
                className='form-control '
                selected={sightingDate}
                onChange={(date) => setSightingDate(date)}
                dateFormat='MMM dd, yyyy'
                minDate={moment().toDate()}
                //   placeholderText={toString(selectedDate)}
                isClearable
                clearButtonTitle='clear'
                required
              />
              <DatePicker
                wrapperClassName='price-wrapper'
                className='form-control'
                selected={sightingDate2}
                onChange={(date) => {
                  if (date >= sightingDate) {
                    setSightingDate2(date)
                  } else {
                    setShow(true)
                    setToast(DATE_VALIDATION)
                  }
                }}
                dateFormat='MMM dd, yyyy'
                minDate={moment().toDate()}
                //   placeholderText={toString(selectedDate)}
                isClearable
                clearButtonTitle='clear'
                required
              />
            </div>
            <Form.Label>
              Time Range
              <i
                data-tip
                data-for='timerange'
                className='fas fa-question-circle question-mark mx-1'
              ></i>{' '}
            </Form.Label>
            <div className='price-range-wrapper'>
              <TimePicker
                step={30}
                className='price-wrapper'
                onChange={handleTimeChange}
                value={value}
              />
              <TimePicker
                step={30}
                className='price-wrapper'
                onChange={handleTimeChange2}
                value={value2}
              />
            </div>
            <div className='price-range-wrapper'>
              <Form.Group controlId='minimum' className='price-wrapper'>
                <Form.Label className='formlabel'>
                  Price Range (Min)
                  <i
                    data-tip
                    data-for='pricemin'
                    className='fas fa-question-circle question-mark mx-1'
                  ></i>{' '}
                </Form.Label>
                <Form.Control
                  type='number'
                  min={0}
                  placeholder='Minimum Price'
                  value={min}
                  onChange={(e) => setMin(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='maximum' className='price-wrapper'>
                <Form.Label className='formlabel'>
                  Price Range (Max)
                  <i
                    data-tip
                    data-for='pricemax'
                    className='fas fa-question-circle question-mark mx-1'
                  ></i>{' '}
                </Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Maximum Price '
                  value={max}
                  min={0}
                  onChange={(e) => setMax(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </div>
            <Form.Label>Select product type</Form.Label>
            <MultiSelect
              SetmushroomType={SetmushroomType}
              Options={OPTIONS}
              mushroomType={mushroomType}
            />
            <Form.Group>
              <Form.Label>Upload images (Optional)</Form.Label>
              <Form.Control
                // id='image-file'
                itemType='image'
                type='file'
                placeholder='select Image'
                accept='image/*'
                // className='form-control-lg gg'
                size='lg'
                multiple
                ref={fileRef}
                // style={{
                //   color: 'transparent',
                //   width: '120px',
                //   backgroundColor: 'white',
                // }}
                onChange={imageHandler}
                onClick={() => {
                  fileRef.current.value = null
                }}
              />
            </Form.Group>
            <ListGroup
              variant='flush'
              className={
                uploadedImage.length > 0 ? 'listcontainer' : 'listcontainer'
              }
              style={{ marginBottom: 10 }}
            >
              {images.map((item, i) => (
                <ListGroup.Item key={item.id}>
                  <Row>
                    <Col md={3}>
                      <Image
                        src={item.relativePath}
                        alt={item.name}
                        fluid
                        rounded
                        height={50}
                        width={50}
                      />
                    </Col>
                    <Col className='imagename' md={5}>
                      {item.name} {item.id} {i}
                    </Col>
                    <Col className='deletecontainer' md={4}>
                      <Button
                        style={{ height: '40px', width: '45px' }}
                        onClick={() => {
                          removeFromLartHandler(i)
                        }}
                        type='button'
                        variant='light'
                      >
                        <i className='fas fa-trash fa-sm'></i>
                      </Button>
                    </Col>
                  </Row>
                  <div className='form-group'>
                    <textarea
                      name='caption'
                      className='form-control my-2'
                      id='captions'
                      value={item.caption}
                      placeholder='Image Caption'
                      required
                      disabled={!user}
                      rows='3'
                      maxLength={MAX_DETAILS_SIZE}
                      onChange={(e) => {
                        onHandle(e, i)
                      }}
                    ></textarea>
                  </div>
                </ListGroup.Item>
              ))}

              {uploadedImage.map((item, i) => (
                <ListGroup.Item>
                  <Row>
                    <Col md={3}>
                      <Image
                        src={
                          'https://gomushroomhunting.s3.us-east-2.amazonaws.com/' +
                          item
                        }
                        fluid
                        rounded
                        height={50}
                        width={50}
                      />
                    </Col>
                    <Col className='imagename' md={5}>
                      {item}
                    </Col>
                    <Col className='deletecontainer' md={4}>
                      <Button
                        style={{ height: '40px', width: '45px' }}
                        onClick={() => {
                          removeImageFromFile(item)
                        }}
                        type='button'
                        variant='light'
                      >
                        <i className='fas fa-trash fa-sm'></i>
                      </Button>
                    </Col>
                  </Row>
                  <div className='form-group'>
                    <textarea
                      name='caption'
                      className='form-control my-2'
                      id='captions'
                      value={captions[i] ? captions[i].caption : ''}
                      placeholder='Image Caption'
                      required
                      disabled={true}
                      rows='3'
                      maxLength={MAX_DETAILS_SIZE}
                      onChange={(e) => {
                        onHandle(e, i)
                      }}
                    ></textarea>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Form.Label>Details (Optional)</Form.Label>
            <div className='form-group'>
              <textarea
                className='form-control'
                id='details'
                required
                value={details}
                disabled={!user}
                rows='7'
                maxLength={MAX_DETAILS_SIZE}
                aria-label='Place Details'
                onChange={(e) => setDetails(e.target.value)}
              ></textarea>
            </div>
            <Button
              type='submit'
              className='buttonSubmit'
              disabled={!user}
              onClick={() => {
                submitHandle()
              }}
            >
              Confirm Changes
            </Button>
          </Container>
        )}

        <DangerToast show={show} setShow={setShow} text={toast} />
        <SuccessToast
          vicshow={vicshow}
          setVicShow={setVicShow}
          text={victoast}
        />
      </div>
    </div>
  )
}

export default EditPostScreen
