import React, { useState, useContext, useCallback } from 'react'
import Map, {
  Layer,
  Feature,
  Marker,
  Source,
  Popup,
  NavigationControl,
  GeolocateControl,
} from 'react-map-gl'
import Modal from 'react-bootstrap/Modal'
import { AuthContext } from '../AuthProvider'
import { Button } from 'react-bootstrap'

import Pin from '../components/Pin'
import LocationMarker from './LocationMarker'
import MushroomCard from './cards/MushroomCard'
import CardMobile from './cards/CardMobile'
// import Marker from './Marker'
// import 'mapbox-gl/dist/mapbox-gl.css'

const Mapbox = (props) => {
  const { isMobile } = props
  const context = useContext(AuthContext)
  const [idChange, setidChange] = useState(null)
  const [currentLocationMarker, setcurrentLocationMarker] = useState(null)
  const [showPopup, setShowPopup] = React.useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const [postcontent, setPostContent] = useState(null)
  const [center, setCenter] = React.useState([0.75, 44])

  const [zoom, setZoom] = React.useState(10)
  const [lat, setLat] = useState(null)
  const [lng, setlng] = useState(null)
  // const mapRef = React.useRef()

  const onMapLoad = React.useCallback(() => {
    mapRef.current.on('move', () => {
      // do something
    })
  }, [])

  const {
    coordinates,
    postMarker,
    refs,
    privateRefs,
    setCoordinates,
    user,
    dropDownText,
    privatePosts,
    setprivatePosts,
    onSelectLocation,
    mapRef,
    geoJsonObj,
    setgeoJsonObj,
    geoPost,
    setGeoPost,
  } = context

  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [-122.8, 37.8] },
      },
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [-118.309129, 33.988296] },
      },
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [-119.25, 34.246] },
      },
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [-120.25, 34.246] },
      },
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [-129.25, 34.246] },
      },
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [-149.25, 34.246] },
      },
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [-133.25, 34.246] },
      },
    ],
  }

  const layerStyle = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 10,
      'circle-color': 'rgba(255, 255, 255, .1)',
    },
  }

  const handleClick = (post, id, longitude, latitude) => {
    console.log(post)
    setPostContent(post)
    setShowPopup(true)
    setlng(longitude)
    setLat(latitude)
    // setModalShow(true)
    refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

    if (idChange) {
      refs[idChange].current.style.borderWidth = '0px'
      setidChange(id)
    } else {
      setidChange(id)
    }

    refs[id].current.style.borderWidth = '2px'
  }
  const handleClickPrivate = (id) => {
    privateRefs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

    if (idChange) {
      privateRefs[idChange].current.style.borderWidth = '0px'
      setidChange(id)
    } else {
      setidChange(id)
    }

    privateRefs[id].current.style.borderWidth = '2px'
  }
  var options = {
    enableHighAccuracy: false,
    timeout: 8000,
    maximumAge: 0,
  }
  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for Geolocation.')
        break
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.')
        break
      case error.TIMEOUT:
        alert('The request to get user location timed out.')
        break
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.')
        break
    }
  }

  const handleLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          formattedAddress: '',
        })

        setcurrentLocationMarker({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
        const data = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }

        onSelectLocation(position.coords.longitude, position.coords.latitude)
        localStorage.setItem('currentlocation', JSON.stringify(data))
      },
      showError,
      options
    )
  }

  // const onSelectLocation = (longitude, latitude) => {
  //   mapRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 })
  // }

  // const onSelectLocation = useCallback((longitude, latitude) => {
  //   mapRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 })
  // }, [])
  const [settings, setSettings] = useState({
    scrollZoom: true,
    boxZoom: true,
    dragRotate: true,
    dragPan: true,
    keyboard: true,
    doubleClickZoom: true,
    touchZoomRotate: true,
    touchPitch: true,
    minZoom: 2,
    maxZoom: 18,
    minPitch: 0,
    maxPitch: 85,
  })

  function features() {
    mapRef.current.queryRenderedFeatures({ layers: [''] })
  }
  function filterRamps(e) {
    geojson.features.filter((feature) => {
      return feature.properties.material === e.target.value
    })
  }

  // function filterRamps(e) {
  //   data.features.filter((feature) => {
  //     return feature.properties.material === e.target.value
  //   })
  // }

  // function filterRamps(e) {
  //   data.features.filter((feature) => {
  //     return feature.properties.material === e.target.value
  //   })
  // }
  function onMapPoints() {
    const features = mapRef.current.queryRenderedFeatures({
      layers: ['point'],
    })

    setGeoPost(features)
  }

  return (
    <div className='map'>
      {/* <button className='current-location' onClick={handleLocation}>
        <i className='fa-solid fa-location-crosshairs fa-2x'></i>
      </button> */}
      {/* <Map
        initialViewState={{
          longitude: -100,
          latitude: 40,
          zoom: 3.5,
        }}
        style={{ width: 600, height: 400 }}
        mapStyle='mapbox://styles/mapbox/streets-v9'
      /> */}

      <Map
        // onDragEnd={() => {
        //   onMapPoints()
        // }}
        onLoad={() => {
          onMapPoints()
        }}
        onZoomEnd={() => {
          onMapPoints()
        }}
        onMoveEnd={() => {
          onMapPoints()
        }}
        // onClick={(e) => {
        //   const features = mapRef.current.queryRenderedFeatures({
        //     layers: ['point'],
        //   })
        //   console.log(features)
        // }}
        {...settings}
        ref={mapRef}
        mapboxAccessToken='pk.eyJ1IjoibWVybHltYWRyaWFnYSIsImEiOiJjbGFhazBiZHgwOGRqM29wbHBxajgyNnprIn0.JZuIB_MmeLMxEUGFfVtLWA'
        initialViewState={{
          latitude: coordinates.latitude ? coordinates.latitude : 47.32,
          longitude: coordinates.longitude ? coordinates.longitude : 120.7401,
          zoom: 10,
        }}
        // style={{ width: '100vw', height: '100vh' }}
        // scrollZoom={true}
        // zoom={9}
        // boxZoom={true}
        // onMove={(evt) => setCoordinates(evt.viewState)}
        // onZoomEnd={(map) => {
        //   setZoom(map.getZoom())
        //   setCenter(map.getCenter())
        // }}
        mapStyle='mapbox://styles/mapbox/streets-v11'
      >
        {showPopup && isMobile && (
          <Popup
            longitude={lng}
            latitude={lat}
            anchor='top'
            onClose={() => setShowPopup(false)}
          >
            <div className='my-2'>
              <CardMobile
                // key={post.properties.id}
                post={postcontent}
                // postref={refs[post.properties.id]}
              />
            </div>
          </Popup>
        )}
        {postMarker.map((post) => (
          <Marker
            longitude={post.location.coordinates[0]}
            latitude={post.location.coordinates[1]}
            anchor='bottom'
          >
            <LocationMarker
              type={post.mushroomType}
              onClick={() => {
                handleClick(
                  post,
                  post._id,
                  post.location.coordinates[0],
                  post.location.coordinates[1]
                )
              }}
            />
          </Marker>
        ))}
        <Source id='my-data' type='geojson' data={geoJsonObj}>
          <Layer {...layerStyle} />
        </Source>
        {/* {currentLocationMarker && (
          <Marker
            longitude={coordinates.longitude}
            latitude={coordinates.latitude}
            anchor='bottom'
          >
            <Pin />
          </Marker>
        )} */}{' '}
        <NavigationControl />
        <GeolocateControl showUserHeading={true} showUserLocation={true} />
      </Map>
    </div>
  )
}

export default Mapbox
