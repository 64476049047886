import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
export const AuthContext = React.createContext({})
const AuthProvider = ({ children }) => {
  const [user, setuser] = useState(null)
  const [authLoader, setAuthLoader] = useState(false)
  const [postMarker, setpostMarker] = useState([])
  const [geoJsonObj, setgeoJsonObj] = useState([])
  const [geoPost, setGeoPost] = useState([])
  const userLocation = localStorage.getItem('currentlocation')
    ? JSON.parse(localStorage.getItem('currentlocation'))
    : null

  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const [coordinates, setCoordinates] = useState({
    latitude: userLocation ? userLocation.latitude : 47.32,
    longitude: userLocation ? userLocation.longitude : -120.7401,
    formattedAddress: '',
  })

  const refs = postMarker.reduce((acc, value) => {
    acc[value._id] = React.createRef()
    return acc
  }, {})

  const mapRef = React.useRef()

  const getLoggedIn = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      setAuthLoader(true)
      const loggedIn = await axios.get('/api/v1/user/loggedin', config)

      if (loggedIn.data) {
        setuser(loggedIn.data)
      } else {
        setuser(loggedIn.data)
      }
      setAuthLoader(false)
    } catch (error) {
      setAuthLoader(false)
    }
  }

  useEffect(() => {
    if (userInfo) {
      getLoggedIn()
    } else {
      setuser(false)
    }
  }, [])

  const onSelectLocation = useCallback((longitude, latitude) => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      zoom: 10,
      duration: 2500,
    })
  }, [])
  return (
    <AuthContext.Provider
      value={{
        coordinates,
        setCoordinates,
        postMarker,
        setpostMarker,
        refs,
        user,
        authLoader,
        setuser,
        onSelectLocation,
        mapRef,
        geoJsonObj,
        setgeoJsonObj,
        geoPost,
        setGeoPost,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
