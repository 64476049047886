import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/gg.svg'
import { ReactComponent as FbLogo } from '../assets/fblisterlogo.svg'
import axios from 'axios'
import { AuthContext } from '../AuthProvider'

import {
  isEmail,
  isMatch,
  isLength,
  isEmpty,
} from '../components/utils/validation'

import Message from '../components/Message'
import Loader from '../components/Loader'
import { Helmet } from 'react-helmet-async'

import thumbnail from '../assets/banner.png'

const metaDecorator = require('../assets/metaDecorator')
const ForgotPasswordScreen = ({ history, location }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const context = useContext(AuthContext)
  const { user, setuser } = context
  const [sentEmail, setsentEmail] = useState('')

  //   useEffect(() => {
  //     if (user) {
  //       history.push('/home')
  //     }
  //   }, [history, user])
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const submitHandler = async (e) => {
    e.preventDefault()

    if (isEmpty(email)) return setError('Please fill in all fields.')

    try {
      setLoading(true)
      const { data } = await axios.post(
        '/api/v1/user/forgotpasswordemail',
        { email },
        config
      )
      if (data) {
        setError('')
        setEmail('')
        setsentEmail(data.message)
        console.log(data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setsentEmail('')

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  return (
    <>
      <Helmet>
        <title> Forgot Password</title>
        <meta
          name='description'
          content='If the user forgets their password they can input their email for a password reset. '
        />
        <link rel='canonical' href='/forgotpassword'></link>
        <meta property='og:title' content='ForgotPassword' />
        <meta
          property='og:description'
          content='If the user forgets their password they can input their email for a password reset. '
        />
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />{' '}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>
      <div className='main'>
        <FormContainer>
          {loading ? (
            <Loader />
          ) : (
            <Form onSubmit={submitHandler}>
              {sentEmail && <Message variant='success'> {sentEmail}</Message>}

              {error && <Message variant='danger'> {error}</Message>}
              {/* <h5 style={{ marginBottom: '1rem' }}>Log in </h5> */}
              <h3>Forgot Password? </h3>
              <p className='text-black-70 py-1'>
                Enter the email address you used when you joined Farms near me
                and we’ll send you instructions to reset your password.
              </p>
              <Form.Group controlId='email'>
                <Form.Label className='formlabel'>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button type='submit' className='w-100 buttonSubmit'>
                Continue
              </Button>
            </Form>
          )}
        </FormContainer>
        <div className='bg-r'></div>
      </div>{' '}
    </>
  )
}

export default ForgotPasswordScreen
