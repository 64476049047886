import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'
// Components
import Sidebar from '../Nav/Sidebar'
import Backdrop from '../Elements/Backdrop'
// Assets
import LogoIcon from '../../assets/svg/Logo'
import BurgerIcon from '../../assets/svg/BurgerIcon'
import { ReactComponent as FbLogo } from '../../assets/fblisterlogo_gg.svg'
import { AuthContext } from '../../AuthProvider'
import { Spinner } from 'react-bootstrap'
export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY)
  const [sidebarOpen, toggleSidebar] = useState(false)
  const context = useContext(AuthContext)
  const { user, setuser, authLoader } = context

  useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY))
    return () => {
      window.removeEventListener('scroll', () => setY(window.scrollY))
    }
  }, [y])

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className='flexCenter animate whiteBg'
        style={y > 100 ? { height: '60px' } : { height: '80px' }}
      >
        <NavInner className='container flexSpaceCenter'>
          <Link className='pointer flexNullCenter' to='home' smooth={true}>
            <FbLogo height='100' width='100' className='pb-2' />

            <h1 style={{ marginLeft: '2px' }} className='font20 extraBold'>
              FBLister
            </h1>
          </Link>
          <BurderWrapper
            className='pointer'
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className='flexNullCenter'>
            <li className='semiBold font17 pointer'>
              <Link
                activeClass='active'
                style={{ padding: '10px 15px' }}
                to='home'
                spy={true}
                smooth={true}
                offset={-80}
              >
                Home
              </Link>
            </li>
            <li className='semiBold font17 pointer'>
              <Link
                activeClass='active'
                style={{ padding: '10px 15px' }}
                to='services'
                spy={true}
                smooth={true}
                offset={-80}
              >
                Services
              </Link>
            </li>
            <li className='semiBold font17 pointer'>
              <Link
                activeClass='active'
                style={{ padding: '10px 15px' }}
                to='blog'
                spy={true}
                smooth={true}
                offset={-80}
              >
                Tutorial
              </Link>
            </li>
            <li className='semiBold font17 pointer'>
              <Link
                activeClass='active'
                style={{ padding: '10px 15px' }}
                to='pricing'
                spy={true}
                smooth={true}
                offset={-80}
              >
                Pricing
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className='flexNullCenter'>
            <li className='semiBold font17 pointer'>
              {authLoader ? (
                <Spinner animation='grow' role='status'>
                  <span className='visually-hidden mx-4'>Loading...</span>
                </Spinner>
              ) : user ? (
                <a href={`/dashboard`} style={{ padding: '10px 30px 10px 0' }}>
                  Dashboard
                </a>
              ) : (
                <a href='login' style={{ padding: '10px 30px 10px 0' }}>
                  Login
                </a>
              )}
            </li>
            <li className='semiBold font17 pointer flexCenter'>
              <a
                href='/'
                className='radius8 lightBg'
                style={{ padding: '10px 15px' }}
              >
                Get Started
              </a>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`
