import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import thumbnail from '../assets/banner.png'

const metaDecorator = require('../assets/metaDecorator')
const ContactScreen = () => {
  const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : ''
    if (subject) params += `subject=${encodeURIComponent(subject)}`

    return (
      <a className='contact-text' href={`mailto:${email}${params}`}>
        {children}
      </a>
    )
  }
  return (
    <>
      <Helmet>
        <title> Contact</title>
        <meta
          name='description'
          content='contact us for any issue or any feedback. User can email us for any problems. Our support will get back to them as soon as possible. '
        />
        <link rel='canonical' href='/contactus'></link>
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />{' '}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>

      <div>
        <Header />
        <Container style={{ marginTop: '3rem' }}>
          <div className='m-auto my-4' style={{ width: '60%' }}>
            <h3>Contact</h3>
            <div className='para-contact'>
              {' '}
              We are a small business and we value customer’s feedback and
              suggestions. Feel free to mail at{' '}
              <Mailto
                email='gomushroomhunting@gmail.com'
                subject='Comment or Suggestion for Gomushroomhunting'
              >
                facebooklister@gmail.com{' '}
              </Mailto>{' '}
              if you face any issue or if you have any questions or suggestions
              for us.{' '}
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default ContactScreen
