import React, { useState, useContext, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/gg.svg'
import { ReactComponent as FbLogo } from '../assets/fblisterlogo.svg'
import axios from 'axios'
import { AuthContext } from '../AuthProvider'
import { useMediaQuery } from 'react-responsive'

import {
  isEmail,
  isMatch,
  isLength,
  isEmpty,
  isUserName,
} from '../components/utils/validation'

import Message from '../components/Message'
import Loader from '../components/Loader'
import logow from '../assets/black.svg'
import { Helmet } from 'react-helmet-async'
import thumbnail from '../assets/banner.png'

const metaDecorator = require('../assets/metaDecorator')
const RegisterScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [checked, setChecked] = useState(false)
  const [name, setName] = useState('')
  const [username, setUserName] = useState('')

  const [error, setError] = useState('')
  const [validationerror, setValidationError] = useState(null)

  const [loading, setLoading] = useState(false)
  const context = useContext(AuthContext)
  const isTabletOrMobile = useMediaQuery({ query: '(max-height: 900px)' })

  const { user, authLoader, setuser } = context

  useEffect(() => {
    if (user) {
      history.push('/')
    }
  }, [history, user])

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const submitHandler = async (e) => {
    e.preventDefault()

    if (
      isEmpty(name) ||
      isEmpty(password) ||
      isEmpty(email) ||
      isEmpty(username)
    )
      return setError('Please fill in all fields.')

    if (!isEmail(email)) return setError('Invalid email format.')

    if (!isUserName(username))
      return setError(
        'Usernames can only contain letters, numbers and underscore '
      )
    if (isLength(password))
      return setError('Password must be at least 6 characters.')

    if (!isMatch(password, confirmPassword)) {
      return setError('Password confirmation did not match.')
    }

    try {
      setLoading(true)
      const { data } = await axios.post(
        '/api/v1/user/register',
        { name, email, password, username },
        config
      )
      // setuser(data)
      setLoading(false)
      setuser(data)
      localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      setLoading(false)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  return (
    <>
      <Helmet>
        <title> Register</title>
        <meta
          name='description'
          content='User can register with their name, email and password. '
        />
        <link rel='canonical' href='/register'></link>
        <meta property='og:title' content='Register' />
        <meta
          property='og:description'
          content='User can register with their name, email and password. '
        />
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />{' '}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>

      <div className='main'>
        <FormContainer>
          <img
            className={isTabletOrMobile ? 'up-icon-r' : 'up-icon'}
            src={logow}
            height={80}
          />
          {loading ? (
            <Loader />
          ) : (
            <Form onSubmit={submitHandler}>
              {error && <Message variant='danger'> {error}</Message>}
              <h5 style={{ marginBottom: '1rem' }}>Register </h5>

              <h3>Welcome to Farm Fresh! </h3>

              <Form.Group controlId='username'>
                <Form.Label className='formlabel'>
                  Username or Storename
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter username'
                  value={username}
                  onChange={(e) => {
                    setUserName(e.target.value)
                    if (e.target.value && !isUserName(e.target.value))
                      setValidationError(
                        'Usernames can only contain letters, numbers and underscore '
                      )
                    else {
                      setValidationError('')
                    }
                  }}
                ></Form.Control>

                {validationerror && (
                  <span style={{ color: 'red' }}>{validationerror}</span>
                )}
              </Form.Group>
              <Form.Group controlId='name'>
                <Form.Label className='formlabel'>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='email'>
                <Form.Label className='formlabel'>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='password'>
                <Form.Label className='formlabel'>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Enter Password'
                  minLength={6}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='confirmpassword'>
                <Form.Label className='formlabel'>Confirm Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Confirm Password'
                  minLength={6}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <div className='privpolicy'>
                <Form.Group className='my-3' controlId='formBasicCheckbox'>
                  <Form.Check
                    className='custom-checkbox'
                    type='checkbox'
                    variant='success'
                    checked={checked}
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                  />
                </Form.Group>

                <div className='text-button-chek py-3 mx-2'>
                  I Agree to the{' '}
                  {/* <Link
                    className='text-link px-1'
                    target='_blank'
                    to={'/register'}
                  >
                    <div className='Text'> Terms of Use </div>
                  </Link> */}
                  {/* and{' '} */}
                  <Link
                    className='text-link'
                    target='_blank'
                    to={{
                      pathname:
                        'https://www.privacypolicies.com/live/c3725ac4-0aa6-42d8-ba67-63b7da26aa27',
                    }}
                  >
                    <div className='Text'> Privacy Policy</div>
                  </Link>
                </div>
              </div>
              <Button
                type='submit'
                className='w-100 buttonSubmit'
                disabled={!checked}
              >
                Register
              </Button>

              <div className='d-flex align-items-center justify-content-center '>
                Already have an Account?
                <Link className='text-link' to={'/login'}>
                  <div className='text-button  px-1'>Log In!</div>
                </Link>
              </div>
              <div className='mandatory'></div>
            </Form>
          )}
        </FormContainer>
        <div className='bg-r'></div>
      </div>
    </>
  )
}

export default RegisterScreen
