import React, { useEffect, useState, useContext, useRef } from 'react'
import { AuthContext } from '../AuthProvider'
import { Link } from 'react-router-dom'
import {
  Form,
  Button,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Image,
} from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import axios from 'axios'
import { DP_SIZE, IMG_SIZE, MAX_BIO_SIZE } from '../constants/Sizes'
import { SIZE_VALIDATION } from '../constants/ToastTexts'
import DangerToast from '../components/toasts/DangerToast'
import SuccessToast from '../components/toasts/SuccessToast'
import Header from '../components/Header'
import { Helmet } from 'react-helmet-async'
import {
  isEmail,
  isMatch,
  isLength,
  isEmpty,
  isUserName,
} from '../components/utils/validation'
import thumbnail from '../assets/banner.png'

const metaDecorator = require('../assets/metaDecorator')
const EditProfileScreen = ({ location, history, match }) => {
  const context = useContext(AuthContext)
  const { user, authLoader, setuser } = context
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [confirmPassword, setconfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const fileRef = useRef(null)
  const [bio, setBio] = useState('')
  const [toast, setToast] = useState('')
  const [show, setShow] = useState(false)
  const [victoast, setVicToast] = useState('')
  const [vicshow, setVicShow] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [username, setUserName] = useState('')
  const [validationerror, setValidationError] = useState(null)

  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null
  useEffect(() => {
    if (user) {
      setEmail(user.email)
      setName(user.name)
      setBio(user.bio)
      setImageUrl(user.image)
      setUserName(user.userName)
      // setImageUrl(user.image)
      // console.log(imageUrl)
    }
  }, [user, history])

  const handleUpload = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('images', image)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      await axios
        .post('/api/v1/upload', formData, config)
        .then(function (response) {
          updateProfile(response.data.files)
        })
    } catch (error) {
      setLoading(false)

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }
  const updateProfile = async (imageUrl) => {
    try {
      setLoading(true)
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.put(
        `/api/v1/user/profile`,
        { name, email, password, bio, username },
        config
      )

      if (data) {
        setuser(data)
        setVicToast('Update Successful')
        setVicShow(true)
        setImage(null)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  const deletePlaceHandler = async () => {
    if (window.confirm('Are you sure you want to delete your profile?')) {
      setLoading(true)
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      try {
        await axios.delete(`/api/v1/user/deleteprofile`, config)
        setLoading(false)
        localStorage.removeItem('userInfo')
        document.location.href = '/login'
      } catch (error) {
        setLoading(false)

        setError(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        )
      }
    } else {
      // Do nothing!
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setError('Passwords do not match')
    } else {
      updateProfile('')
    }
  }
  const imageHandler = async (e) => {
    const file = e.target.files[0]

    file['relativePath'] = URL.createObjectURL(file)
    console.log(file)
    if (file.size < DP_SIZE) {
      setImage(file)
    } else {
      setShow(true)
      setToast(SIZE_VALIDATION)
    }
  }

  const removeFromLartHandler = () => {
    setImage(null)
  }

  const handleRemoveImage = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`/api/v1/user/profile`)

      setuser(data)
      setLoading(false)
      if (data) {
        setVicToast('Profile picture Removed')
        setVicShow(true)
      }
    } catch (error) {
      setLoading(false)
      setShow(true)
      setToast(error.response.data.message)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  return (
    <>
      <Helmet>
        <title> Edit Profile</title>
        <meta
          name='description'
          content='User can edit their profile in this page, they can change their Name , bio and password.'
        />
        <link rel='canonical' href='/editprofile'></link>
        <meta property='og:title' content='Home' />
        <meta
          property='og:description'
          content='User can edit their profile in this page, they can change their Name , bio and password.'
        />
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />{' '}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>
      <div>
        <Header />
        <div className='my-5'>
          {authLoader ? (
            <Loader />
          ) : user ? (
            <FormContainer>
              <h1>EDIT PROFILE </h1>
              {error && <Message variant='danger'> {error}</Message>}
              {loading && <Loader />}
              <Form onSubmit={submitHandler}>
                <Form.Group controlId='username'>
                  <Form.Label className='formlabel'>Username</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter username'
                    value={username}
                    onChange={(e) => {
                      setUserName(e.target.value)
                      if (e.target.value && !isUserName(e.target.value))
                        setValidationError(
                          'Usernames can only contain letters, numbers and underscore '
                        )
                      else {
                        setValidationError('')
                      }
                    }}
                  ></Form.Control>

                  {validationerror && (
                    <span style={{ color: 'red' }}>{validationerror}</span>
                  )}
                </Form.Group>
                <Form.Group controlId='name'>
                  <Form.Label className='formlabel'>Name</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Enter Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='email'>
                  <Form.Label className='formlabel'>Email Address</Form.Label>
                  <Form.Control
                    type='email'
                    disabled
                    placeholder='Enter Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='password'>
                  <Form.Label className='formlabel'>Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter password'
                    minLength={6}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='confrimpassword'>
                  <Form.Label className='formlabel'>Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Confirm password'
                    value={confirmPassword}
                    onChange={(e) => setconfirmPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='bio'>
                  <Form.Label className='formlabel'>Bio</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    value={bio}
                    maxLength={MAX_BIO_SIZE}
                    onChange={(e) => setBio(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Button
                  type='submit'
                  variant='primary'
                  className='buttonSubmit'
                >
                  Confirm Change
                </Button>
              </Form>
              <Button
                // type='submit'
                // className='py-2'
                onClick={deletePlaceHandler}
                variant='danger'
                // className='buttonSubmit'
              >
                Delete your Profile{' '}
              </Button>
            </FormContainer>
          ) : null}

          <DangerToast show={show} setShow={setShow} text={toast} />
          <SuccessToast
            vicshow={vicshow}
            setVicShow={setVicShow}
            text={victoast}
          />
        </div>
      </div>
    </>
  )
}

export default EditProfileScreen
