import React from 'react'
import styled from 'styled-components'
// Components
import BlogBox from '../Elements/BlogBox'
import FullButton from '../Buttons/FullButton'
import TestimonialSlider from '../Elements/TestimonialSlider'
import YouTube, { YouTubeProps } from 'react-youtube'

export default function Blog() {
  const opts = {
    height: '450',
    width: '800',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }

  const onReady = (event) => {
    event.target.pauseVideo()
  }

  return (
    <Wrapper id='blog'>
      <div className='whiteBg'>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Tutorial</h1>
            <p className='font13'>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <YouTube videoId='2g811Eo7K8U' opts={opts} onReady={onReady} />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`
