import React, { useContext, useState, useEffect, useRef } from 'react'
import {
  Container,
  Form,
  FormControl,
  Button,
  ListGroup,
  Row,
  Col,
  Image,
  Alert,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import MultiSelect from '../components/MultiSelect'
import PlaceAutocomplete from '../components/PlaceAutocomplete'
import DangerToast from '../components/toasts/DangerToast'
import { IMG_SIZE, MAX_DETAILS_SIZE } from '../constants/Sizes'
import {
  COUNT_VALIDATION,
  INPUT_VALIDATION,
  PLACE_VALIDATION,
  SIZE_VALIDATION,
  PRICE_VALIDATION,
  DATE_VALIDATION,
} from '../constants/ToastTexts'
import ReactTooltip from 'react-tooltip'
import { AuthContext } from '../AuthProvider'
import axios from 'axios'
import Loader from '../components/Loader'
import Message from '../components/Message'
import SuccessToast from '../components/toasts/SuccessToast'
import AuthLoader from '../components/AuthLoader'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { OPTIONS } from '../constants/Datas'
import TimePicker from 'react-bootstrap-time-picker'
import Header from '../components/Header'
import { Helmet } from 'react-helmet-async'
import thumbnail from '../assets/banner.png'
import heic2any from 'heic2any'

const metaDecorator = require('../assets/metaDecorator')

const PostScreen = ({ history }) => {
  const context = useContext(AuthContext)
  const fileRef = useRef(null)
  const refPrice = useRef('')
  const refminPrice = useRef('')

  const dateRef = useRef('')
  const dateReff = useRef('')

  const [sightingDate, setSightingDate] = useState(new Date())
  const [sightingDate2, setSightingDate2] = useState(new Date())

  const [mushroomType, SetmushroomType] = useState([])
  const [images, setImages] = useState([])
  const [captionsArr, setCaptionsArr] = useState([])
  const [caption, setCaption] = useState('')

  const [toast, setToast] = useState('')
  const [show, setShow] = useState(false)
  const [victoast, setVicToast] = useState('')
  const [vicshow, setVicShow] = useState(false)
  const [errorMessage, seterrorMessage] = useState(null)
  const [details, setDetails] = useState('')
  const [likes, setLikes] = useState([])
  const [loading, setLoading] = useState(false)
  const { coordinates, user, authLoader } = context
  const [title, setTitle] = useState('')
  const [privateSwitch, setprivateSwitch] = useState(false)
  const [value, setTime] = useState('10:00 AM')
  const [value2, setTime2] = useState('10:00 AM')
  const [max, setMax] = useState(null)
  const [min, setMin] = useState(null)
  const [formVal, setFormVal] = useState([])

  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null
  const imageHandler = async (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      let newImage = e.target.files[i]

      if (
        (newImage && newImage.name.includes('.HEIC')) ||
        newImage.name.includes('.heic')
      ) {
        // get image as blob url
        let blobURL = URL.createObjectURL(newImage)

        // convert "fetch" the new blob url
        let blobRes = await fetch(blobURL)

        // convert response to blob
        let blob = await blobRes.blob()

        // convert to PNG - response is blob
        let conversionResult = await heic2any({
          blob,
          toType: 'image/jpeg',
          quality: 0.7, // adjust quality as needed
        })
        let newFile = new File([conversionResult], 'ios-heic.jpeg', {
          type: 'image/jpeg',
        })

        console.log(newFile)
        newImage = newFile
        // convert to blob url
        newImage['relativePath'] = URL.createObjectURL(conversionResult)
      } else {
        newImage['relativePath'] = URL.createObjectURL(newImage)
      }
      newImage['id'] = Math.floor(Math.random() * 100)

      if (newImage.size < IMG_SIZE) {
        if (images.length <= 10) {
          setImages((prevImages) => [...prevImages, newImage])
          setFormVal([...formVal, { caption: '' }])
        } else {
          setShow(true)
          setToast(COUNT_VALIDATION)
        }
      } else {
        setShow(true)
        setToast(SIZE_VALIDATION)
      }
    }
  }
  const removeFromLartHandler = (i) => {
    const imagess = [...images]
    imagess.splice(i, 1)
    setImages(imagess)

    const newForm = [...formVal]
    newForm.splice(i, 1)
    setFormVal(newForm)
  }

  const createMainPost = async (files) => {
    setLoading(true)

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    try {
      await axios
        .post(
          '/api/v1/places',
          {
            files,
            mushroomType,
            coordinates,
            details,
            likes,
            sightingDate,
            sightingDate2,
            title,
            max,
            min,
            value,
            value2,
            formVal,
          },
          config
        )
        .then(function (response) {
          setVicToast(response.data.message)
          setVicShow(true)
          setLoading(false)
          setImages([])
          SetmushroomType([])
          seterrorMessage('')
          window.postMessage('Post created')
          console.log(response.data)
          history.push(`/item/${response.data.id}`)
          // alert(victoast)
          alert('Post created Successfully')
        })
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  const createPost = async () => {
    setLoading(true)
    const formData = new FormData()
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i])
    }

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      await axios
        .post('/api/v1/upload', formData, config)
        .then(function (response) {
          createMainPost(response.data.files)
        })
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }

  const onHandle = (e, i) => {
    let newForm = [...formVal]

    newForm[i][e.target.name] = e.target.value

    setFormVal(newForm)
  }

  const onRemove = (i) => {
    const newForm = [...formVal]
    newForm.splice(i, 1)
    setFormVal(newForm)
  }

  const submitHandle = () => {
    if (coordinates.formattedAddress) {
      if (mushroomType.length > 0 && sightingDate && title) {
        // console.log(min >= 0)
        // console.log(max >= 0)
        if (min >= 0 && max >= 0 && min <= max) {
          if (sightingDate <= sightingDate2) {
            if (images.length > 0) {
              console.log(formVal)
              createPost()
            } else {
              createMainPost('')
              console.log(formVal)
            }
          } else {
            setShow(true)
            setToast(DATE_VALIDATION)
            window.postMessage(DATE_VALIDATION)

            dateRef.current.style.borderColor = '#FF3333'
            dateReff.current.style.borderColor = 'red'
          }
        } else {
          refPrice.current.style.borderColor = '#FF3333'
          refminPrice.current.style.borderColor = 'red'
          setShow(true)
          setToast(PRICE_VALIDATION)
          window.postMessage(PRICE_VALIDATION)
        }
      } else {
        setShow(true)
        setToast(INPUT_VALIDATION)
        window.postMessage(INPUT_VALIDATION)
      }
    } else {
      setShow(true)
      setToast(PLACE_VALIDATION)
      window.postMessage(PLACE_VALIDATION)
    }
  }

  function handleTimeChange(time) {
    console.log(moment.utc(time * 1000).format('LT')) // <- prints "3600" if "01:00" is picked

    setTime(moment.utc(time * 1000).format('LT'))
  }

  function handleTimeChange2(time) {
    setTime2(moment.utc(time * 1000).format('LT'))
  }
  return (
    <>
      <Helmet>
        <title> Create Post</title>
        <meta
          name='description'
          content='List down your product you want to sell to our website. Put in the title of your item, short description, and some pictures to entice your customer. You need to also enter the dates that your product will be available to visit and buy by your customers.'
        />
        <link rel='canonical' href='/add-product'></link>
        <meta property='og:title' content='Home' />
        <meta
          property='og:description'
          content='List down your product you want to sell to our website. Put in the title of your item, short description, and some pictures to entice your customer. You need to also enter the dates that your product will be available to visit and buy by your customers.'
        />
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />{' '}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>
      <div>
        <Header />
        <div className='main-flow'>
          <ReactTooltip id='registerTip' place='top' effect='solid'>
            Put a title for your product
          </ReactTooltip>
          <ReactTooltip id='locationTip' place='top' effect='solid'>
            This is where you found the mushroom. Input can be address, <br />
            landmark, park, city, zip code, county, state, or country.
          </ReactTooltip>
          <ReactTooltip id='privatemode' place='top' effect='solid'>
            Enable Private mode, Only you can see your post and mushroom
            location.
          </ReactTooltip>

          <ReactTooltip id='pricemax' place='top' effect='solid'>
            Put a valid Maximum Price. Maximum Price should be greater than
            minimum price.
          </ReactTooltip>
          <ReactTooltip id='pricemin' place='top' effect='solid'>
            Put a valid Minimum Price. Minimum Price should be lesser than
            maximum price.
          </ReactTooltip>
          <ReactTooltip id='daterange' place='top' effect='solid'>
            Put a valid date range. Date range depicts the availability of your
            products in those dates.
          </ReactTooltip>
          <ReactTooltip id='timerange' place='top' effect='solid'>
            Put a valid time range. Time range depicts the available time to
            purchase your product.
          </ReactTooltip>

          {loading ? (
            <div className='insider'>
              <div>
                <Loader />
                <h6 className='my-3'>Please Wait</h6>
              </div>
            </div>
          ) : (
            <Container className='post-container'>
              {authLoader ? (
                <AuthLoader />
              ) : user ? null : (
                <Alert
                  variant='warning'
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  Please
                  <Link className='text-link mx-1' to={'/login'}>
                    <div className='Text'> Login </div>
                  </Link>
                  to create post{' '}
                </Alert>
              )}
              {errorMessage ? (
                <Message variant='danger'>{errorMessage}</Message>
              ) : null}
              <h1>Add your product </h1>
              <Form.Label>
                Address <span className='req'> (Required) </span>
                <i
                  data-tip
                  data-for='locationTip'
                  className='fas fa-question-circle question-mark'
                ></i>{' '}
              </Form.Label>{' '}
              <PlaceAutocomplete buttonVisible={false} inputWidth={'25rem'} />
              <Form.Group controlId='title'>
                <Form.Label>
                  Title <span className='req'> (Required) </span>
                  <i
                    data-tip
                    data-for='registerTip'
                    className='fas fa-question-circle question-mark'
                  ></i>{' '}
                </Form.Label>
                <Form.Control
                  disabled={!user}
                  required
                  maxLength={100}
                  type='text'
                  placeholder='Post Title'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Label>
                Date <span className='req'> (Required) </span>
                <i
                  data-tip
                  data-for='daterange'
                  className='fas fa-question-circle question-mark mx-1'
                ></i>{' '}
              </Form.Label>
              <div className='price-range-wrapper'>
                <DatePicker
                  ref={dateReff}
                  wrapperClassName='price-wrapper'
                  className='form-control '
                  selected={sightingDate}
                  onChange={(date) => setSightingDate(date)}
                  dateFormat='MMM dd, yyyy'
                  minDate={moment().toDate()}
                  //   placeholderText={toString(selectedDate)}
                  isClearable
                  clearButtonTitle='clear'
                  required
                />
                <DatePicker
                  ref={dateRef}
                  wrapperClassName='price-wrapper'
                  className='form-control'
                  selected={sightingDate2}
                  onChange={(date) => {
                    if (date >= sightingDate) {
                      setSightingDate2(date)
                    } else {
                      setShow(true)
                      setToast(DATE_VALIDATION)
                    }

                    setSightingDate2(date)
                  }}
                  dateFormat='MMM dd, yyyy'
                  minDate={moment().toDate()}
                  //   placeholderText={toString(selectedDate)}
                  isClearable
                  clearButtonTitle='clear'
                  required
                />
              </div>
              <Form.Label>
                Time <span className='req'> (Required) </span>
                <i
                  data-tip
                  data-for='timerange'
                  className='fas fa-question-circle question-mark mx-1'
                ></i>{' '}
              </Form.Label>
              <div className='price-range-wrapper'>
                <TimePicker
                  step={30}
                  className='price-wrapper'
                  onChange={handleTimeChange}
                  value={value}
                />
                <TimePicker
                  step={30}
                  className='price-wrapper'
                  onChange={handleTimeChange2}
                  value={value2}
                />
              </div>
              <Form.Label className='formlabel'>
                Price <span className='req'> (Required) </span>
                <i
                  data-tip
                  data-for='pricemin'
                  className='fas fa-question-circle question-mark mx-1'
                ></i>{' '}
              </Form.Label>
              <div className='price-range-wrapper'>
                <Form.Control
                  ref={refminPrice}
                  type='number'
                  min={0}
                  className='price-wrapper'
                  placeholder='Minimum Price'
                  value={min}
                  onChange={(e) => setMin(e.target.value)}
                ></Form.Control>

                <Form.Control
                  ref={refPrice}
                  type='number'
                  placeholder='Maximum Price '
                  value={max}
                  className='price-wrapper'
                  max={0}
                  onChange={(e) => setMax(e.target.value)}
                ></Form.Control>
              </div>
              <Form.Label>
                Select product type <span className='req'> (Required) </span>
              </Form.Label>
              <MultiSelect
                SetmushroomType={SetmushroomType}
                Options={OPTIONS}
              />
              <Form.Group>
                <Form.Label>
                  Upload images <span className='req'> (Optional) </span>
                </Form.Label>
                <Form.Control
                  // id='image-file'
                  disabled={!user}
                  itemType='image'
                  type='file'
                  placeholder='select Image'
                  accept='image/jpg, image/jpeg, image/png, image/heic'
                  // className='form-control-lg gg'
                  size='lg'
                  multiple
                  ref={fileRef}
                  // style={{
                  //   color: 'transparent',
                  //   width: '120px',
                  //   backgroundColor: 'white',
                  // }}
                  onChange={imageHandler}
                  onClick={() => {
                    fileRef.current.value = null
                  }}
                />
              </Form.Group>
              <ListGroup
                variant='flush'
                className={
                  images.length > 0 ? 'listcontainer' : 'listcontainer-b '
                }
                style={{ marginBottom: 10 }}
              >
                {images.map((item, i) => (
                  <ListGroup.Item key={item.id}>
                    <Row>
                      <Col md={3}>
                        <Image
                          src={item.relativePath}
                          alt={item.name}
                          fluid
                          rounded
                          height={50}
                          width={50}
                        />
                      </Col>
                      <Col className='imagename' md={5}>
                        {item.name} {item.id} {i}
                      </Col>
                      <Col className='deletecontainer' md={4}>
                        <Button
                          style={{ height: '40px', width: '45px' }}
                          onClick={() => {
                            removeFromLartHandler(i)
                          }}
                          type='button'
                          variant='light'
                        >
                          <i className='fas fa-trash fa-sm'></i>
                        </Button>
                      </Col>
                    </Row>
                    <div className='form-group'>
                      <textarea
                        name='caption'
                        className='form-control my-2'
                        id='captions'
                        value={item.caption}
                        placeholder='Image Caption'
                        required
                        disabled={!user}
                        rows='3'
                        maxLength={MAX_DETAILS_SIZE}
                        onChange={(e) => {
                          onHandle(e, i)
                        }}
                      ></textarea>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <Form.Label>
                Details <span className='req'> (Optional) </span>
              </Form.Label>
              <div className='form-group'>
                <textarea
                  className='form-control'
                  id='details'
                  required
                  disabled={!user}
                  rows='7'
                  maxLength={MAX_DETAILS_SIZE}
                  aria-label='Place Details'
                  onChange={(e) => setDetails(e.target.value)}
                ></textarea>
              </div>
              <Button
                type='submit'
                className='buttonSubmit'
                disabled={!user}
                onClick={() => {
                  submitHandle()
                }}
              >
                Add Product
              </Button>
            </Container>
          )}

          <DangerToast show={show} setShow={setShow} text={toast} />
          <SuccessToast
            vicshow={vicshow}
            setVicShow={setVicShow}
            text={victoast}
          />
        </div>
      </div>
    </>
  )
}

export default PostScreen
