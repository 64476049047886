import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const FormContainer = ({ children }) => {
  return (
    <Container
      className='d-flex align-items-center justify-content-center'
      // style={{
      //   minHeight: '52vh',
      //   height: '50vh',
      //   // width: '600px',
      //   minWidth: '400px',
      //   maxWidth: '600px',
      //   maxHeight: '92vh',
      //   borderRadius: '10px',
      // }}
    >
      <div className='w-100' style={{ maxWidth: '450px' }}>
        {children}
      </div>
    </Container>
  )
}

export default FormContainer
