import React, { Component, useState } from 'react'
import Select from 'react-select'
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  OPTION_COLOR,
} from '../constants/Colors'
import { OPTIONS, PRODUCT_OPTION } from '../constants/Datas'

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 50,
    height: 'auto',
    accentColor: ' #8ebdb1',
    boxShadow: state.isFocused ? `0px 0px 0px 2px ${BORDER_COLOR}` : 'none',
  }),
}
const MultiSelect = (props) => {
  const { SetmushroomType, mushroomType, Options } = props

  return (
    <Select
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#d2d4d5',
          primary25: OPTION_COLOR,
        },
      })}
      placeholder='Select'
      isClearable
      isMulti
      isSearchable
      options={PRODUCT_OPTION}
      value={mushroomType}
      onChange={SetmushroomType}
      styles={customStyles}
    />
  )
}

export default MultiSelect
