import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import HomeScreenMap from './screens/HomeScreenMap'

import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'

import AuthProvider, { AuthContext } from './AuthProvider'
import EditProfileScreen from './screens/EditProfileScreen'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import ChangePasswordScreen from './screens/ChangePasswordScreen'
import ContactScreen from './screens/ContactScreen'
import Landing from './screens/Landing'
import Header from './components/Header'
import PostScreen from './screens/PostScreen'
import PlaceDetails from './screens/PlaceDetailsScreen'
import UserProfileScreen from './screens/UserProfileScreen'
import EditPostScreen from './screens/EditPostScreen'
function App() {
  return (
    <AuthProvider>
      <Router>
        <Route path='/login' component={LoginScreen} exact />

        <Route path='/register' component={RegisterScreen} exact />
        <Route path='/forgotpassword' component={ForgotPasswordScreen} exact />
        <Route
          path='/resetpassword/:id?'
          component={ChangePasswordScreen}
          exact
        />
        {/* <Header /> */}

        <Route path='/item/:id' component={PlaceDetails} exact />

        <Route path='/' component={HomeScreenMap} exact />
        <Route path='/add-product' component={PostScreen} exact />

        <Route path='/contactus' component={ContactScreen} exact />
        <PrivateRoute path='/editpost/:id' component={EditPostScreen} exact />

        <PrivateRoute path='/profile/:id' component={UserProfileScreen} exact />
        <PrivateRoute path='/editprofile' component={EditProfileScreen} exact />
      </Router>
    </AuthProvider>
  )
}

export default App
