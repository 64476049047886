import React, { useState, useContext, useEffect } from 'react'
import {
  Navbar,
  Nav,
  NavDropdown,
  Spinner,
  Button,
  Container,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { AuthContext } from '../AuthProvider'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { ReactComponent as FbLogo } from '../assets/fblisterlogo.svg'
import listerlogo from '../assets/fblisterlogo.png'
import logow from '../assets/white.svg'
import PlaceAutocomplete from './PlaceAutocomplete'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'

const Header = () => {
  const buttonVisible = true
  const context = useContext(AuthContext)
  const { user, authLoader } = context
  const isTabletOrMobile = useMediaQuery({ query: '(max-height: 900px)' })
  let history = useHistory()

  const logoutHandler = () => {
    try {
      localStorage.removeItem('userInfo')
      document.location.href = '/login'
    } catch (error) {}
  }

  return (
    <header>
      <Navbar
        expand='lg'
        collapseOnSelect
        className='navbar navbar-expand-lg navbar-dark navbar-c'
      >
        {isTabletOrMobile ? (
          <div onClick={() => history.goBack()}>
            {window.location.href === 'https://farmsnearme.app/' ? null : (
              <i className='fas fa-arrow-left fa-xl back'></i>
            )}
          </div>
        ) : (
          <LinkContainer to='/'>
            {/* <Navbar.Brand> Fblister</Navbar.Brand> */}
            <img src={logow} height={70} />
          </LinkContainer>
        )}

        {/* <PlaceAutocomplete buttonVisible={true} inputWidth={'25rem'} /> */}

        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ms-auto px-3'>
            <LinkContainer to='/' exact>
              <Nav.Link>
                <i className='fas fa-home'></i> Home
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to='/add-product' exact>
              <Nav.Link>
                <i className='fas fa-plus-circle'></i> Add Product
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to='/contactus' exact>
              <Nav.Link>
                <i className='fas fa-envelope'></i> Contact us
              </Nav.Link>
            </LinkContainer>

            {authLoader ? (
              <Spinner animation='grow' role='status'>
                <span className='visually-hidden mx-4'>Loading...</span>
              </Spinner>
            ) : user ? (
              <NavDropdown title={user.name} alignRight flip>
                <LinkContainer
                  to={`/profile/${user.userName ? user.userName : user._id}`}
                  exact
                >
                  <NavDropdown.Item className='nav-dropdown-item'>
                    Profile
                  </NavDropdown.Item>
                </LinkContainer>

                <NavDropdown.Item
                  className='nav-dropdown-item'
                  onClick={logoutHandler}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <LinkContainer to='/login'>
                <Nav.Link>
                  <i className='fas fa-user'></i> Sign In
                </Nav.Link>
              </LinkContainer>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
