import React, { useState, useRef, useContext } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete'
// import { PLACE_VALIDATION } from '../constants/ToastTexts'
import { AuthContext } from '../AuthProvider'
// import DangerToast from './toasts/DangerToast'
import Geocode from 'react-geocode'
import AuthLoader from './AuthLoader'
import { useMediaQuery } from 'react-responsive'

const { REACT_APP_GOOGLE_API_KEY } = process.env
const PlaceAutocomplete = (props) => {
  const context = useContext(AuthContext)
  const { coordinates, setCoordinates, user, authLoader, onSelectLocation } =
    context

  const { buttonVisible, inputWidth, formatadress } = props
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [address, setAddress] = useState(formatadress)
  const [show, setShow] = useState(false)
  const [viewLocation, setviewLocation] = useState(false)
  const inputRef = useRef(null)
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }
  const searchOptions = {
    componentRestrictions: { country: ['us'] },
  }
  const handleSelect = async (value) => {
    try {
      if (value) {
        const formattedAddress = await geocodeByAddress(value)
        const latlng = await getLatLng(formattedAddress[0])

        setCoordinates({
          latitude: latlng.lat,
          longitude: latlng.lng,
          formattedAddress: formattedAddress[0].formatted_address,
        })
        onSelectLocation(latlng.lng, latlng.lat)
        setAddress(formattedAddress[0].formatted_address)
        inputRef.current && inputRef.current.blur()
      } else {
        inputRef.current && inputRef.current.blur()
      }
    } catch (error) {
      setShow(true)
      inputRef.current && inputRef.current.blur()
    }
  }

  const onEvent = () => {
    if (!address) {
      inputRef.current && inputRef.current.focus()
    } else {
      handleSelect(address)
    }
  }
  Geocode.setApiKey(REACT_APP_GOOGLE_API_KEY)

  const handleLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (response) => {
            const address = response.results[0].formatted_address
            setAddress(address)

            setCoordinates({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              formattedAddress: address,
            })

            onSelectLocation(
              position.coords.longitude,
              position.coords.latitude
            )
          },
          (error) => {
            console.log(error)
          }
        )
      },
      showError,
      options
    )
    inputRef.current && inputRef.current.blur()

    setviewLocation(false)
  }
  const handleFocus = (e) => {
    setviewLocation(true)
  }
  const handleBlur = (e) => {
    if (
      e.nativeEvent.explicitOriginalTarget &&
      e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget
    ) {
      return
    }

    if (viewLocation) {
      setTimeout(() => {
        setviewLocation(false)
      }, 200)
    }
  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for Geolocation.')
        break
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.')
        break
      case error.TIMEOUT:
        alert('The request to get user location timed out.')
        break
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.')
        break
    }
  }

  return (
    <div className='mx-3'>
      <div
        className={
          isTabletOrMobile
            ? 'null'
            : buttonVisible
            ? 'headerbutton-box'
            : 'headerbutton-box-head'
        }
      >
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
          // searchOptions={searchOptions} to all over world
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className='form-control-search '>
              <input
                onFocus={handleFocus}
                onBlurCapture={handleBlur}
                ref={inputRef}
                {...getInputProps({
                  placeholder: 'Search',
                  disabled: user ? false : true,
                  className: 'form-control',
                  type: 'search',
                  style: {
                    // marginRight: '30rem',
                    // width: '60%%',
                    marginBottom: 2,
                  },
                })}
              />

              <div className='autocomplete-dropdown-container'>
                {viewLocation ? (
                  <div
                    className={
                      buttonVisible
                        ? 'location-dropdown'
                        : 'location-dropdown-post'
                    }
                    onClick={handleLocation}
                  >
                    <i
                      className='fas fa-location-arrow'
                      style={{ paddingRight: 10 }}
                    ></i>{' '}
                    <div onClick={() => {}}> Current Location</div>
                  </div>
                ) : null}
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' }
                  return (
                    <div>
                      <div
                        className={
                          buttonVisible
                            ? 'autocomplete-dropdown'
                            : 'autocomplete-dropdown-post'
                        }
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                      >
                        <i
                          className='fas fa-map-marker-alt'
                          style={{ paddingRight: 10 }}
                        ></i>{' '}
                        <div className='bottom-text'>
                          {suggestion.formattedSuggestion.mainText} <br />
                          <p> {suggestion.formattedSuggestion.secondaryText}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        {buttonVisible ? (
          authLoader ? (
            <Spinner animation='grow' role='status'>
              <span className='visually-hidden mx-4'>Loading...</span>
            </Spinner>
          ) : (
            <Button
              className={
                isTabletOrMobile ? 'headerbutton-mobile' : 'headerbutton'
              }
              // style={{ marginBottom: 5 }}
              onClick={onEvent}
              variant='outline-light'
              disabled={user ? false : true}
            >
              {' '}
              {user ? 'Search' : 'Search'}
            </Button>
          )
        ) : null}
      </div>
      {/* <DangerToast show={show} setShow={setShow} text={PLACE_VALIDATION} /> */}
    </div>
  )
}

export default PlaceAutocomplete
