import React from 'react'
import { MUSHROOM_SIZE } from '../constants/Sizes'

const LocationMarker = ({ lat, lng, type, onClick }) => {
  const renderIco = () => {
    if (type.length > 1) {
      return (
        <img alt='mushroom icon' src={'others.svg'} height={35} width={35} />
      )
    } else {
      switch (type[0].value) {
        case 'plants':
          return (
            <img
              src={'plants.svg'}
              height={MUSHROOM_SIZE}
              alt='plants icon'
              width={MUSHROOM_SIZE}
            />
          )
        case 'mushrooms':
          return (
            <img
              alt='mushroom icon'
              src={'mushrooms.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'vegetables':
          return (
            <img
              alt='mushroom icon'
              src={'all.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'handmade':
          return (
            <img
              alt='handmade icon'
              src={'handmade.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'spices':
          return (
            <img
              alt='spices icon'
              src={'all.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'honey':
          return (
            <img
              alt='honey icon'
              src={'honey.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'eggs':
          return (
            <img
              alt='eggs icon'
              src={'eggs.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'meat':
          return (
            <img
              alt='meat icon'
              src={'meat.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'herbs':
          return (
            <img
              alt='all icon'
              src={'herbs.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'fruits':
          return (
            <img
              alt='fruits icon'
              src={'fruits.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'dairy':
          return (
            <img
              alt='dairy icon'
              src={'dairy.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'flowers':
          return (
            <img
              alt='dairy icon'
              src={'flowers.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        case 'feed':
          return (
            <img
              alt='dairy icon'
              src={'feed.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
        default:
          return (
            <img
              alt='mushroom icon'
              src={'others.svg'}
              height={MUSHROOM_SIZE}
              width={MUSHROOM_SIZE}
            />
          )
      }
    }
  }

  return (
    <div className='location-marker' onClick={onClick} type={type}>
      {renderIco()}
    </div>
  )
}

export default LocationMarker
