import React, { useState } from 'react'
import { ToastContainer, Toast } from 'react-bootstrap'
const SuccessToast = (props) => {
  const { vicshow, setVicShow, text } = props

  const onClose = () => {
    setVicShow(false)
  }
  return (
    <ToastContainer className='p-3' position='top-end'>
      <Toast
        show={vicshow}
        onClose={onClose}
        bg='success'
        delay={3000}
        autohide
      >
        <Toast.Header closeButton={true}>
          <strong className='me-auto'>Success!</strong>
        </Toast.Header>
        <Toast.Body className={'text-white'}>{text}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default SuccessToast
