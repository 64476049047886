import React, {
  useState,
  useEffect,
  useContext,
  createRef,
  useRef,
} from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  DropdownButton,
  Form,
  Dropdown,
  Accordion,
} from 'react-bootstrap'

import MushroomCard from '../components/cards/MushroomCard'
import Footer from '../components/Footer'
import Mapbox from '../components/Map'
import axios from 'axios'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { AuthContext } from '../AuthProvider'
import { useMediaQuery } from 'react-responsive'
import Header from '../components/Header'
import { clearStorage } from 'mapbox-gl'
import { Helmet } from 'react-helmet-async'

import thumbnail from '../assets/banner.png'

const metaDecorator = require('../assets/metaDecorator')
const HomeScreen = ({ history }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, seterrorMessage] = useState(null)
  const [pastMonths, setpastMonths] = useState([])
  const context = useContext(AuthContext)
  const {
    postMarker,
    setpostMarker,
    refs,
    user,
    geoJsonObj,
    setgeoJsonObj,
    geoPost,
    setGeoPost,
  } = context
  const postRef = useRef(null)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const isLarge = useMediaQuery({ query: '(max-width: 2000px)' })
  const [width, setWidth] = useState(0)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768
  const [show, setShow] = useState(isTabletOrMobile)
  const [isListView, setisListView] = useState(!isTabletOrMobile)
  const [privatePosts, setprivatePosts] = useState([])
  const [dropDownText, setdropDownText] = useState('All Post')

  const fetchEvents = async () => {
    try {
      setLoading(true)
      await axios.get('api/v1/places').then(function (response) {
        setpostMarker(response.data.data)

        setgeoJsonObj(response.data.geoJsonObj)
        // setgeoJsonObj()
        // clearStorage(postMarker)
        console.log(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      seterrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )

      setLoading(false)
    }
  }
  var monthNames = [
    {
      name: 'January',
      num: 1,
    },
    { name: 'February', num: 2 },
    { name: 'March', num: 3 },
    { name: 'April', num: 4 },
    { name: 'May', num: 5 },
    { name: 'June', num: 6 },
    { name: 'July', num: 7 },
    { name: 'August', num: 8 },
    { name: 'Spetember', num: 9 },
    { name: 'October', num: 10 },
    { name: 'November', num: 11 },
    { name: 'December', num: 12 },
  ]
  // const handleScroll = (id) => {
  //   postRef.current.scrollIntoView({ behavior: 'smooth' })
  // }
  const [filters, setFilters] = useState([])
  const getPast6months = () => {
    var monthNames = [
      {
        name: 'January',
        num: 1,
      },
      { name: 'February', num: 2 },
      { name: 'March', num: 3 },
      { name: 'April', num: 4 },
      { name: 'May', num: 5 },
      { name: 'June', num: 6 },
      { name: 'July', num: 7 },
      { name: 'August', num: 8 },
      { name: 'September', num: 9 },
      { name: 'October', num: 10 },
      { name: 'November', num: 11 },
      { name: 'December', num: 12 },
    ]

    var today = new Date()
    var d
    var month
    var year
    let months = []
    for (var i = 6; i >= 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1)
      month = monthNames[d.getMonth()]
      year = d.getFullYear()

      months.push({ name: month, year: year })
    }
    setpastMonths(months.reverse())
  }

  const handleAlertClick = () => {
    if (isListView) {
      setisListView(false)
    } else {
      setisListView(true)
    }
  }

  useEffect(() => {
    fetchEvents()
    // getPast6months()
  }, [])

  // useEffect(() => {
  //   fetchPrivateEvents()
  // }, [])

  const getFilteredData = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      await axios
        .put(
          `/api/v1/places/filter/month`,
          {
            filters,
          },
          config
        )
        .then(function (response) {
          setpostMarker(response.data.data)
        })
    } catch (error) {}
  }
  const handleChange = (e) => {
    const { name, checked } = e.target
    if (checked) {
      if (!filters.includes(name)) {
        let arr = filters
        arr.push(parseInt(name))
        setFilters(arr)
        getFilteredData()
      }
    } else {
      let ind = filters.indexOf(
        filters.filter(function (item) {
          return item == name
        })[0]
      )
      filters.splice(ind, 1) //deleting
      setFilters(filters)

      getFilteredData()
    }

    let tempUser = pastMonths.map((user) =>
      user.name.num == name ? { ...user, isChecked: checked } : user
    )
    setpastMonths(tempUser)
  }
  return (
    <>
      {' '}
      <Helmet>
        <title> Home </title>
        <meta
          name='description'
          content='Welcome to our Homepage! 
      Search and browse through our interactive map site and find local 
      farms or any agricultural products within your area for a competitive price.
      Search, pin, and locate organic local produce near you!'
        />
        <link rel='canonical' href='/'></link>
        <meta property='og:title' content='Home' />
        <meta
          property='og:description'
          content='Welcome to our Homepage! 
      Search and browse through our interactive map site and find local 
      farms or any agricultural products within your area for a competitive price.
      Search, pin, and locate organic local produce near you!'
        />
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />{' '}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>
      <div>
        <Header />

        <div className='main-home'>
          {isTabletOrMobile ? (
            <Row>
              {isListView ? (
                <Col className='container-right-mobile'>
                  {loading ? (
                    <Loader />
                  ) : errorMessage ? (
                    <Message variant='danger'>{errorMessage}</Message>
                  ) : (
                    <Row className='g-4 mobile-margin'>
                      {/* <Ad /> */}

                      {postMarker.length == 0 ? (
                        <Container className='footer-position'>
                          <h1>No Post Available on Map </h1>
                          <p>Drag the map to see available sales and farms</p>
                        </Container>
                      ) : (
                        postMarker.map((post) => (
                          <Col className='mx-2'>
                            {/* <Button onClick={() => handleClick(post._id)} /> */}

                            <MushroomCard
                              key={post._id}
                              post={post}
                              postref={refs[post._id]}
                            />
                          </Col>
                        ))
                      )}
                    </Row>
                  )}
                  {isTabletOrMobile ? (
                    <div className='alert-mid'>
                      <Alert
                        variant='dark'
                        onClose={() => setShow(false)}
                        // show={show}
                        className='alert-mobile'
                        onClick={handleAlertClick}
                      >
                        {isListView ? 'Map View' : 'List View'}
                      </Alert>
                    </div>
                  ) : null}
                  <div className='footer-position'>
                    <Footer />
                  </div>
                </Col>
              ) : (
                <Col md={9} className='map-container-mobile'>
                  <Mapbox isMobile={isTabletOrMobile} />
                </Col>
              )}
            </Row>
          ) : (
            <Row>
              <Col md={9} className='map-container'>
                <Mapbox isMobile={isTabletOrMobile} />{' '}
              </Col>
              <Col className='container-right-mobile'>
                {loading ? (
                  <Loader />
                ) : errorMessage ? (
                  <Message variant='danger'>{errorMessage}</Message>
                ) : (
                  <Row className='g-4'>
                    {/* <Ad /> */}

                    {geoPost.length == 0 ? (
                      <Container className='footer-position'>
                        <h1>No Post Available</h1>
                        <p>Drag the map to see available sales and farms</p>
                      </Container>
                    ) : (
                      geoPost.map((post) => (
                        <Col
                          className={
                            isLarge
                              ? ' col-xxl-12 col-xl-12 col-lg-12'
                              : 'col-xxl-6 col-xl-12 col-lg-12'
                          }
                        >
                          <MushroomCard
                            key={post.properties.id}
                            post={post.properties}
                            postref={refs[post.properties.id]}
                          />
                        </Col>
                      ))
                    )}

                    {isTabletOrMobile ? (
                      <div className='alert-mid'>
                        <Alert
                          variant='dark'
                          onClose={() => setShow(false)}
                          // show={show}
                          className='alert-mobile'
                          onClick={handleAlertClick}
                        >
                          {isListView ? 'List View' : 'Map View'}
                        </Alert>
                      </div>
                    ) : null}
                  </Row>
                )}
                <div className='footer-position'>
                  <Footer />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  )
}

export default HomeScreen
