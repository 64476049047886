import React, { useState, useContext, useEffect } from 'react'
import Avatar from 'react-avatar'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import { API_URL, AVATAR_URL } from '../constants/Datas'
import { AuthContext } from '../AuthProvider'
import axios from 'axios'
import AuthLoader from '../components/AuthLoader'
import MushroomCard from '../components/cards/MushroomCard'
import Message from '../components/Message'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Helmet } from 'react-helmet-async'

import thumbnail from '../assets/banner.png'

const metaDecorator = require('../assets/metaDecorator')
const UserProfileScreen = ({ history, match }) => {
  const context = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const { user, authLoader } = context
  const [postCreator, setpostCreator] = useState(null)
  const [userload, setuserLoad] = useState(false)
  const [userPost, setUserPost] = useState([])
  const [error, setError] = useState('')
  const getuserbyID = async () => {
    console.log(match.params.id)

    try {
      setuserLoad(true)
      const { data } = await axios.get(
        `/api/v1/user/getuser/${match.params.id}`
      )
      setpostCreator(data)
      setuserLoad(false)
    } catch (error) {
      setuserLoad(false)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }
  const getuserPost = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `/api/v1/places/userposts/${match.params.id}`
      )
      setUserPost(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  useEffect(() => {
    getuserbyID()
    getuserPost()
  }, [match, history])

  return (
    <>
      <Helmet>
        <title>Profile </title>
        <meta
          name='description'
          content='Welcome to your profile page. You can edit your name, short bio, and your profile picture here. You can also see the listed items that you have listed on our website. You can click on the image of the product listed if you want to edit them.
          '
        />
        <link rel='canonical' href='/profile/:id'></link>
        <meta property='og:title' content='Profile' />
        <meta
          property='og:description'
          content='Welcome to your profile page. You can edit your name, short bio, and your profile picture here. You can also see the listed items that you have listed on our website. You can click on the image of the product listed if you want to edit them.
  '
        />
        <meta
          property='og:image'
          content={metaDecorator.hostname + thumbnail}
        />
        <meta
          property='og:url'
          content={
            metaDecorator.hostname +
            window.location.pathname +
            window.location.search
          }
        />{' '}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image:alt'
          content='The logo of Farms near me. The green leaves symbolizes our goal for greener earth and all organic products'
        />
      </Helmet>
      <div>
        <Header />

        <div className='main-flow'>
          {userload ? (
            <Loader />
          ) : postCreator ? (
            <Container className='post-container'>
              <Row>
                <Col
                  md={3}
                  className='profile-avatar'
                  style={{
                    justifyContent: 'flex-start',
                  }}
                >
                  <Avatar
                    src={
                      postCreator.image
                        ? API_URL + postCreator.image
                        : AVATAR_URL
                    }
                    size={140}
                    className='sb-avatar'
                    style={{ margin: 10 }}
                  />
                </Col>
                <Col
                  style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                  }}
                >
                  <Row className='py-4'>
                    <Col className='h3'>{postCreator.name}</Col>

                    <Row>
                      <Col md={5} className='h4'>
                        {userPost.length}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5} className='h4'>
                        Posts
                      </Col>
                    </Row>
                  </Row>
                </Col>
                <Row
                  style={{
                    display: 'flex',
                  }}
                >
                  <Col md={5} style={{ textAlign: 'justify' }}></Col>
                </Row>
                <Row style={{ textAlign: 'justify' }} className='my-2 mx-2'>
                  {' '}
                  {postCreator.bio}
                </Row>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {authLoader ? (
                    <AuthLoader />
                  ) : user._id === postCreator._id ? (
                    <Row>
                      <Col md={4}>
                        <Link to='/editprofile'>
                          <Button
                            type='submit'
                            variant='primary'
                            className='buttonSubmit'
                          >
                            Edit Profile
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  ) : null}
                </Row>
              </Row>
              <Row
                className='g-4 my-3'
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant='danger'>{error}</Message>
                ) : userPost.length == 0 ? (
                  <Container className='footer-position'>
                    <h1> NO post by user.</h1>
                  </Container>
                ) : (
                  userPost.map((post) => (
                    <Col md={6}>
                      {/* <Button onClick={() => handleClick(post._id)} /> */}

                      <MushroomCard key={post._id} post={post} />
                    </Col>
                  ))
                )}
                <div className='my-3'></div>
              </Row>{' '}
            </Container>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default UserProfileScreen
