export const OPTIONS = [
  { value: 'others/unknown', label: 'Others/Unknown' },
  { value: 'amanita', label: 'Amanita' },
  { value: 'agaricus', label: 'Agaricus' },
  { value: 'bolete', label: 'Bolete' },
  { value: 'chanterelle', label: 'Chanterelle' },
  { value: 'coral', label: 'Coral' },
  { value: 'chickenofthewoods', label: 'Chicken Of The Woods' },
  { value: 'deathcapmushroom', label: 'Death Cap Mushroom' },
  { value: 'hydnellumpeckii', label: 'Hydnellum Peckii' },
  { value: 'honeyfungus', label: 'Honey Fungus' },
  { value: 'lionsmane', label: 'LionsMane' },
  { value: 'lactarius', label: 'Lactarius' },
  { value: 'morel', label: 'Morel' },
  { value: 'matsutake', label: 'Matsutake' },
  { value: 'milkycap', label: 'Milky Cap' },
  { value: 'maitake', label: 'Maitake' },
  { value: 'oyster', label: 'Oyster' },
  { value: 'parasol', label: 'Parasol' },
  { value: 'psilocybin', label: 'Psilocybin' },
  { value: 'reishi', label: 'Reishi' },
  { value: 'stinkhorn', label: 'Stinkhorn' },
  { value: 'turkeytail', label: 'Turkey Tail' },
]

export const PRODUCT_OPTION = [
  { value: 'plants', label: 'Plants' },
  { value: 'mushrooms', label: 'Mushrooms' },
  { value: 'vegetables', label: 'Vegetables' },
  { value: 'herbs', label: 'Herbs' },

  { value: 'spices', label: 'Spices' },
  { value: 'eggs', label: 'Eggs' },
  { value: 'meat', label: 'Meat' },
  { value: 'honey', label: 'Honey' },
  { value: 'handmade', label: 'Handmade Product' },
  { value: 'fruits', label: 'Fruits' },
  { value: 'dairy', label: 'Dairy' },
  { value: 'flowers', label: 'Flowers' },
  { value: 'feed', label: 'Feed' },
]

export const AVATAR_URL =
  'https://upload.wikimedia.org/wikipedia/commons/5/59/User-avatar.svg'
export const API_URL = 'https://gomushroomhunting.s3.us-east-2.amazonaws.com/'

export const PRIVACY_POLICY_URL =
  'https://docs.google.com/document/d/e/2PACX-1vQXLeB2WSMQVaZzmEOUZY2gUFQuiTIYMcAGoihNA7vgahY6X1nmGZ0fY8CouqzwpV34zePTO66h0TpT/pub'
export const TERMS_OF_USE_URL =
  'https://docs.google.com/document/d/e/2PACX-1vRf3bcBymUpV6AaWLlffrw2pnxG7padXdjwGeOX7DDiwd1uP6Sspz6oIDQFVZfPuAQvIrto5ipwBt2Z/pub'
export const ETSY_STORE_URL = 'https://www.etsy.com/shop/themushroomhunter'

export const AMAZON_AFF_URL = 'https://amzn.to/3FsrNRj'

export const OFFER_URL = 'https://www.mushroomclub.co/offer'
